import React, {useReducer, useState, useRef} from "react";
import './BetCalculator.scss';
import LineRow from "./LineRow";
import NumberFormat from 'react-number-format';

const BetCalculator = ({showClose, setBetCalculatorModal}) => {
  const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}), {
    betAmount: 0,
    payoutTotal: 0,
    teams: 1,
    payoutReduced: 0
  });

  const [inputList, setInputList] = useState([{payout: 0, odds: 0}]);

  const handleInputChange = (e, index) => {
    const {name, value} = e.target;
    const list = [...inputList];
    list[index][name] = value;


    if (list[index].odds > 0) {
      list[index].payout = (list[index].odds / 100) + 1;
    } else if (list[index].odds < 0) {
      list[index].payout = (100 / list[index].odds) - 1;
    } else {
      // setInputList({...inputList, payout: 0});
      list[index].payout = 0;
      // setUserInput({...userInput, payoutReduced: 0});
    }
    // console.log(inputList);
    setInputList(list);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, {payout: 0, odds: 0}]);
  };


  const handleChange = evt => {
    const {target: {name, value}} = evt;
    setUserInput({[name]: value});
  }

  const calculatePayoutTotal = () => {
    const payoutTotal = Math.abs(userInput.payoutReduced * userInput.betAmount) - userInput.betAmount;
    setUserInput({...userInput, payoutTotal})
  }

  const clearAll = () => {
    // window.location.reload();
    setUserInput({betAmount: 0, payoutTotal: 0, payoutReduced: 0, teams: 1});
    setInputList([{payout: 0, odds: 0}])
  }

  const handleTotalPayout = (totalPayout) => {
    setUserInput({...userInput, payoutReduced: totalPayout});
  }

  const inputEl = useRef(null);

  function handleFocus() {
    inputEl.current.select();
  }

  return (
    <div style={{ position: "relative"}}>
      {showClose && <span className="close" style={{position: "absolute", right: "15px", zIndex: "1", top: "30px"}}
                          onClick={() => setBetCalculatorModal(false)}>&times;</span>}
      <div className="bet__calculator__container">
        {/* <h3>BET CALCULATOR</h3> */}
        <div className="bet__calculator__title">
          <h5>Teams</h5>
          <h5>Payout</h5>
          <h5>Odds</h5>
          <h5>Action</h5>
        </div>

        {[...Array(userInput.teams)].map((e, i) => {
            return (
              <LineRow key={i} handleTotalPayout={handleTotalPayout} handleInputChange={handleInputChange}
                       handleRemoveClick={handleRemoveClick} handleAddClick={handleAddClick} inputList={inputList}/>
            )
          }
        )}

        <div className="bet__calculator__amount__container">
          <span className="bet__calculator__amount__span">Bet Amount ($)</span>
          <input type="number" className="bet__calculator__amount__input"
                 style={{border: Number(userInput.betAmount) === 0 && '1px solid red'}} name="betAmount"
                 value={userInput.betAmount}
                 onChange={handleChange} onFocus={handleFocus} ref={inputEl}/>
        </div>
        <div className="bet__calculator__amount__container">
          <span className="bet__calculator__amount__span">Payout Total</span>
          <NumberFormat thousandSeparator={true} prefix={'$'} type="text" className="bet__calculator__amount__input"
                        readOnly name="payoutTotal"
                        value={userInput.payoutTotal.toFixed(2)}
                        onChange={handleChange}/>
        </div>

        <div style={{display: "flex", marginTop: "20px"}}>
          {/*{userInput.payoutReduced === 0 && <p>Please fill out the entire form</p>}*/}
          <button className="bet__calculator__calculate__button" onClick={calculatePayoutTotal} style={{
            background: (Number(userInput.betAmount) === 0 || Number(userInput.odds) === 0 || Number(userInput.payoutReduced) === 0) && "#ececec",
            cursor: (Number(userInput.betAmount) === 0 || Number(userInput.odds) === 0 || Number(userInput.payoutReduced) === 0) && "not-allowed",
            color: (Number(userInput.betAmount) === 0 || Number(userInput.odds) === 0 || Number(userInput.payoutReduced) === 0) && "rgb(15, 35, 105)"
          }}
                  disabled={userInput.payoutReduced === 0}>CALCULATE
          </button>
          <button className="bet__calculator__clear__button" onClick={clearAll}>CLEAR</button>
        </div>

      </div>
    </div>
  );
}

export default BetCalculator;