import React from "react";
import "./AutoScroll.scss";
import {Link} from "react-router-dom";
import Marquee from "react-smooth-marquee";

const AutoScroll = ({lines}) => {

    const mapLines = () => lines.sort((a, b) => (a.StartDateTime > b.StartDateTime) ? 1 : ((b.StartDateTime > a.StartDateTime) ? -1 : 0)).map(line => (
        // <p className="marquee">
        <Link key={line.Game_Num}
              className="link"
              to={`#`}
              style={
                  {
                      borderRight: "1px solid white",
                      padding: "10px",
                      // paddingLeft: "750px !important",
                      color: "white !important"
                  }
              }>
            {/*(${line?.J_TotalPoints} O ${line?.J_OverPrice}) vs ${line?.AwayTeam_Desc} (${line?.J_TotalPoints} U ${line?.J_UnderPrice})`}}*/}
            <span
                className="auto__scroll__away__team" dangerouslySetInnerHTML={{__html: `${line?.AwayTeam_Desc}`}}/>
            <span
                style={{color: "green"}}>({line?.J_TotalPoints} O {line?.J_OverPrice}) </span>
            <span className="auto__scroll__vs">vs</span> <span className="auto__scroll__home__team">{line.HomeTeam_Desc}
            <span
                style={{color: "red"}}>({line?.J_TotalPoints} U {line?.J_UnderPrice})</span></span>
        </Link>
    ))
    return (
        <section id="auto__scroll">
            <Marquee>
                {lines.length === 0 ?
                    <span>PLEASE CHECK BACK LATER FOR AVAILABLE GAMES. THANK YOU FOR VISITING ILOVETO.BET HAVE A GOOD NIGHT!</span> :
                    mapLines()}
            </Marquee>
        </section>

    )
}

export default AutoScroll;
