import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styles from "../../Components/SelectLeague/SelectLeague.module.scss";
import "./LiveScores.scss";
import "./LiveScoresDetails.scss";
import noData from "../../Assets/images/no-data.jpg";
import StreetLines from "../../Components/StreetLines";
import Countdown from "react-countdown";
import { ImSpinner8 } from "react-icons/im";
import Lottie from "react-lottie";
import animationBaseballData from "../../Assets/lottie/baseball.json";
import animationBasketballData from "../../Assets/lottie/basketball.json";
import animationSoccerData from "../../Assets/lottie/soccer.json";
import animationFightingData from "../../Assets/lottie/boxing.json";
import animationHockeyData from "../../Assets/lottie/hockey.json";
import animationFootballData from "../../Assets/lottie/football.json";
import Spinner from "../../Components/Spinner/Spinner";

// Hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const LiveScores = ({ modal, openModal, closeModal }) => {
  const [sportId, setSportId] = useState(4);
  const [sportsMenu, setSportsMenu] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [value, onChange] = useState(new Date());
  const [dateValue, setDateValue] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState("");
  const eventsRef = useRef(null);
  const [filteredMenu, setFilteredMenu] = useState([]);
  const [loadingMenu, setLoadingMenu] = useState(true);

  const prevAmount = usePrevious(sportId);

  useEffect(() => {
    fetchAllSportsData().then((res) => {
      setFilteredMenu(res);
      let index = res.findIndex((object) => {
        return object?.sport_id === sportId;
      });
      setSportId((s) => res[index]?.sport_id || res[0]?.sport_id || s);
      // setSportId((s) => s || res[0]?.sport_id);
      setLoadingMenu(false);
    });
  }, [sportsMenu]);

  useEffect(() => {
    // setLoadingMenu(true);
    fetchAllSportsData().then((res) => {
      setFilteredMenu(res);
      let index = res.findIndex((object) => {
        return object?.sport_id === sportId;
      });
      setSportId((s) => res[index]?.sport_id || res[0]?.sport_id || s);
      setLoadingMenu(false);
    });

    // return () => setLoadingMenu(true);
  }, [dateValue]);

  const fetchAllSportsData = async () => {
    const arr = [];
    setLoadingMenu(true);
    // setLoadingData(true);
    for (let i = 0; i < sportsMenu.length; i++) {
      let uid = sportsMenu[i].sport_id;
      const response = await fetch(
        `api/liveScores/${uid}/${moment(dateValue.toISOString()).format()}`
      );
      const res = await response.json();
      const r = JSON.parse(res).events;
      if (r.length === 0) {
      } else {
        arr.push(sportsMenu[i]);
      }
    }
    // setSportId(arr[0]);
    setLoadingMenu(false);
    return arr;
  };

  const fetchData = async () => {
    const response = await fetch(
      `api/liveScores/${sportId}/${moment(dateValue.toISOString()).format()}`
    );
    const res = await response.json();
    const r = JSON.parse(res).events;
    if (r) {
      setEventData(r);
    } else {
      setEventData([]);
    }
    setLoadingData(false);
    // setLoadingMenu(false);
  };

  const fetchIds = async () => {
    const response = await fetch(`/api/sportIds`);
    const res = await response.json();
    const r = JSON.parse(res).sports;
    setSportsMenu(r);
  };
  const prevData = usePrevious(eventData);
  const prevFilteredData = usePrevious(filteredData);
  // const prevSportId = usePrevious(sportId);

  useEffect(() => {
    prevData?.map((games, index) => {
      const awayDiff = Math.abs(
        Number(
          prevData[index]?.score.score_away_by_period[
            prevData[index]?.score.score_away_by_period.length - 1
          ]
        ) -
          Number(
            eventData[index]?.score.score_away_by_period[
              eventData[index]?.score.score_away_by_period.length - 1
            ]
          )
      );
      if (awayDiff > 0) {
        // console.log(games, index, awayDiff);
      }
    });
  }, [sportId, eventData]);

  useEffect(() => {
    fetchIds();
  }, []);

  useEffect(() => {
    fetchData();
    const timer = setInterval(() => {
      fetchData();
    }, 10000);

    return () => {
      // setLoadingMenu(false);
      setLoadingData(true);
      clearInterval(timer);
    };
  }, [sportId, dateValue]);

  const setSport = (id) => setSportId(id);

  const renderStatusText = (status) => {
    switch (status) {
      case "STATUS_IN_PROGRESS":
      case "STATUS_IN_PROGRESS_2":
        return "Match In Progress";
      case "STATUS_FINAL":
      case "STATUS_FULL_TIME":
        return "Match Completed";
      case "STATUS_SCHEDULED":
        return "Match Scheduled";
      case "STATUS_CANCELED":
      case "STATUS_FORFEIT":
        return "Match Canceled";
      case "STATUS_END_PERIOD":
      case "STATUS__END__OF__ROUND":
        return "Match Period Ended";
      case "STATUS_HALFTIME":
        return "Match In Half Time";
      case "STATUS_POSTPONED":
        return "Match Postponed";
      case "STATUS_SUSPENDED":
        return "Match Suspended";
      case "STATUS_DELAYED":
        return "Match Delayed";
      case "STATUS_RAIN_DELAY":
        return "Match Rain Delay";
      case "STATUS_FIRST_HALF":
        return "Match In First Half";
      case "STATUS_SECOND_HALF":
        return "Match In Second Half";
      case "STATUS_PRE_FIGHT":
        return "Match About To Start";
      default:
        return status;
    }
  };
  const renderStatusColor = (status) => {
    switch (status) {
      case "STATUS_IN_PROGRESS":
      case "STATUS_IN_PROGRESS_2":
      case "STATUS_END_PERIOD":
      case "STATUS__END__OF__ROUND":
      case "STATUS_SECOND_HALF":
        return "#D6CE93";
      case "STATUS_FINAL":
      case "STATUS_FULL_TIME":
        return "green";
      case "STATUS_SCHEDULED":
        return "orange";
      case "STATUS_CANCELED":
        return "#d62828";
      case "STATUS_RAIN_DELAY":
      case "STATUS_POSTPONED":
        return "#4F5D75";
      case "STATUS_SUSPENDED":
        return "#d00000";
      case "STATUS_DELAYED":
        return "#9c6644";
      default:
        return status;
    }
  };
  const renderSportBG = () => {
    switch (sportId) {
      case 1:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/ncaaFootballBG.jpg";
      case 2:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/nflBG.jpeg";
      case 3:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/mlbBG.jpg";
      case 4:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/nbaBG.jpeg";
      case 5:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/ncaaBasketball.jpg";
      case 6:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/nhlBG.jpeg";
      case 7:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/ufcBG.jpg";
      case 8:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/nbaBG.jpeg";
      case 9:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/nflBG.jpeg";
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/soccerBG.jpeg";
      default:
        return "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/liveScoresBG.png";
    }
  };
  const renderSportName = () => {
    switch (sportId) {
      case 1:
        return "NCAA-Football";
      case 2:
        return "NFL";
      case 3:
        return "MLB";
      case 4:
        return "NBA";
      case 5:
        return "NCAA-Basketball";
      case 6:
        return "NHL";
      case 7:
        return "UFC";
      case 8:
        return "WNBA";
      case 9:
        return "CFL";
      case 10:
        return "MLS";
      case 11:
        return "EPL";
      case 12:
        return "FRA1";
      case 13:
        return "GER1";
      case 14:
        return "ESP1";
      case 15:
        return "ITA1";
      case 16:
        return "UEFACHAMP";
      default:
        return "NBA";
    }
  };
  // const isNCAAFootball = () => eventData.sport_id === 1;
  const isNFL = () => eventData?.sport_id === 2 || sportId === 2;
  // const isMLB = (event) => event.sport_id === 3;
  const isNBA = () => eventData?.sport_id === 4 || sportId === 4;
  // const isNCAABasketball = (event) => event.sport_id === 5;
  const isNHL = (event) => event?.sport_id === 6 || sportId === 6;
  const isUFC = (event) => event?.sport_id === 7 || sportId === 7;
  const isWNBA = () => eventData?.sport_id === 8 || sportId === 8;
  // const isCFL = () => eventData.sport_id === 9;
  const isMLS = (event) => event?.sport_id === 10 || sportId === 10;

  const handleChange = ({ target: { value } }) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    if (searchQuery !== "") {
      filterGames();
    }
  }, [searchQuery, eventData]);

  const filterGames = () => {
    let results = eventData.filter((event) => {
      return event.teams_normalized.some(({ name, mascot }) => {
        let teamName = event.sport_id !== 7 ? `${name} ${mascot}` : name;
        return (
          name.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
          mascot.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
          teamName.toLowerCase().includes(searchQuery.toLowerCase().trim())
        );
      });
    });
    setFilteredData(results);
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <div className="overlay_timer">
          {days === 0 ? null : days > 1 ? `${days} Days` : `${days} Day`}{" "}
          {hours === 0 ? null : hours > 1 ? `${hours} Hours` : `${hours} Hour`}{" "}
          {minutes === 0
            ? null
            : minutes > 1
            ? `${minutes} Minutes`
            : `${minutes} Minute`}{" "}
          {seconds === 0
            ? null
            : seconds > 1
            ? `${seconds} Seconds`
            : `${seconds} Second`}
        </div>
      );
    }
  };

  const renderLottieAnimation = () => {
    switch (sportId) {
      case 1:
        return animationFootballData;
      case 2:
        return animationFootballData;
      case 3:
        return animationBaseballData;
      case 4:
        return animationBasketballData;
      case 5:
        return animationBasketballData;
      case 6:
        return animationHockeyData;
      case 7:
        return animationFightingData;
      case 8:
        return animationBasketballData;
      case 9:
        return animationFootballData;
      case 10:
        return animationSoccerData;
      case 11:
        return animationSoccerData;
      case 12:
        return animationSoccerData;
      case 13:
        return animationSoccerData;
      case 14:
        return animationSoccerData;
      case 15:
        return animationSoccerData;
      case 16:
        return animationSoccerData;
      default:
        return animationBasketballData;
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: renderLottieAnimation(),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const renderFilteredGames = (lines, previousData) => {
    return lines?.map((fd, index) => (
      <div key={fd.event_id} className="event__container animate__animated">
        <div className="ribbon ribbon-top-right">
          <span
            style={{
              backgroundColor: renderStatusColor(fd.score.event_status),
              border: `5px solid ${renderStatusColor(fd.score.event_status)}`,
            }}
          >
            {fd.score.event_status_detail || "Scheduled"}
          </span>
        </div>
        <div
          id="liveScoresDetails"
          style={{
            paddingBottom:
              fd.score.event_status === "STATUS_SCHEDULED" &&
              fd.score.score_home_by_period.length === 0
                ? "30px"
                : 0,
          }}
        >
          {previousData &&
            previousData.length === lines.length &&
            previousData[index]?.score.score_away_by_period[
              previousData[index]?.score.score_away_by_period.length - 1
            ] -
              lines[index]?.score.score_away_by_period[
                previousData[index]?.score.score_away_by_period.length - 1
              ] <
              0 &&
            Math.abs(
              Number(
                previousData[index]?.score.score_away_by_period[
                  previousData[index]?.score.score_away_by_period.length - 1
                ]
              ) -
                Number(
                  lines[index]?.score.score_away_by_period[
                    lines[index]?.score.score_away_by_period.length - 1
                  ]
                )
            ) !== 0 && (
              <div
                className={`${
                  previousData &&
                  previousData[index]?.score.score_away_by_period[
                    previousData[index]?.score.score_away_by_period.length - 1
                  ] -
                    lines[index]?.score.score_away_by_period[
                      previousData[index]?.score.score_away_by_period.length - 1
                    ] <
                    0
                    ? "overlay_away animate-pulse"
                    : ""
                }`}
              >
                {previousData[index]?.score.score_away_by_period[
                  previousData[index]?.score.score_away_by_period.length - 1
                ] !== undefined ? (
                  <span className="font-bold">
                    +
                    {Math.abs(
                      Number(
                        previousData[index]?.score.score_away_by_period[
                          previousData[index]?.score.score_away_by_period
                            .length - 1
                        ]
                      ) -
                        Number(
                          lines[index]?.score.score_away_by_period[
                            lines[index]?.score.score_away_by_period.length - 1
                          ]
                        )
                    )}
                  </span>
                ) : null}
                <Lottie options={defaultOptions} height={200} width={200} />
              </div>
            )}
          {previousData &&
            previousData.length === lines.length &&
            previousData[index]?.score.score_home_by_period[
              previousData[index]?.score.score_home_by_period.length - 1
            ] -
              lines[index]?.score.score_home_by_period[
                previousData[index]?.score.score_home_by_period.length - 1
              ] <
              0 &&
            Math.abs(
              Number(
                previousData[index]?.score.score_home_by_period[
                  previousData[index]?.score.score_home_by_period.length - 1
                ]
              ) -
                Number(
                  lines[index]?.score.score_home_by_period[
                    lines[index]?.score.score_home_by_period.length - 1
                  ]
                )
            ) !== 0 && (
              <div
                className={`${
                  previousData &&
                  previousData[index]?.score.score_home_by_period[
                    previousData[index]?.score.score_home_by_period.length - 1
                  ] -
                    lines[index]?.score.score_home_by_period[
                      previousData[index]?.score.score_home_by_period.length - 1
                    ] <
                    0
                    ? "overlay_home animate-pulse"
                    : ""
                }`}
              >
                {previousData[index]?.score.score_home_by_period[
                  previousData[index]?.score.score_home_by_period.length - 1
                ] !== undefined ? (
                  <span className="font-bold">
                    +
                    {Math.abs(
                      Number(
                        previousData[index]?.score.score_home_by_period[
                          previousData[index]?.score.score_home_by_period
                            .length - 1
                        ]
                      ) -
                        Number(
                          lines[index]?.score.score_home_by_period[
                            lines[index]?.score.score_home_by_period.length - 1
                          ]
                        )
                    )}
                  </span>
                ) : null}
                <Lottie options={defaultOptions} height={200} width={200} />
              </div>
            )}
          {fd.score.event_status === "STATUS_SCHEDULED" &&
            fd.score.score_home_by_period.length === 0 && (
              <Countdown date={fd.event_date} renderer={renderer} />
            )}
          {/*<div className="overlay_home">{prevFilteredData[index]?.score.score_home_by_period[prevData[index]?.score.score_home_by_period.length - 1] !== undefined ? prevData[index]?.score.score_home_by_period[prevData[index]?.score.score_home_by_period.length - 1] - eventData[index]?.score.score_home_by_period[prevData[index]?.score.score_home_by_period.length - 1] : null}</div>*/}
          <div className="game-info">
            <div className="game-info__status">
              <div className="game-info__status">
                <span id="game-final">
                  {renderStatusText(fd.score.event_status)}
                  <span className="mobile-only">
                    - {fd.score.event_status_detail}
                  </span>
                </span>
              </div>
            </div>
            <div className="location-info">
              <div className="venue-info">
                {fd.score.venue_name} — {fd.score.venue_location}
              </div>
              <span style={{ textAlign: "center" }}>
                <div className="season-type">
                  {fd.schedule.season_type}{" "}
                  {!isUFC(fd) &&
                    `(${fd.schedule.event_name.split("-")[0].trim()})`}
                </div>
                <div className="broadcast-large">{fd.score.broadcast}</div>
              </span>
            </div>
            <div className="game-info__teams away-winner">
              <div className="aTeam-info team-info">
                <div className="team-name-and-ranking">
                  {!isUFC() ? (
                    <img
                      className="sportsLogo"
                      src={`https://sportslogos.nyc3.cdn.digitaloceanspaces.com/logos/${fd.teams_normalized[0].team_id}.png`}
                      alt={" Logo"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://www.escapeauthority.com/wp-content/uploads/2116/11/No-image-found.jpg";
                      }}
                    />
                  ) : (
                    <img
                      className="sportsLogo"
                      src={`https://sportslogos.nyc3.cdn.digitaloceanspaces.com/logos/${fd.teams_normalized[0].team_id}.png`}
                      alt={" Logo"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/no-profile-image.png";
                      }}
                    />
                  )}
                  <div className="team-name">{fd.teams_normalized[0].name}</div>
                  <div className="team-mascot">
                    {fd.sport_id !== 7 &&
                      fd.sport_id !== 11 &&
                      fd.sport_id !== 12 &&
                      fd.sport_id !== 13 &&
                      fd.sport_id !== 14 &&
                      fd.sport_id !== 15 &&
                      fd.sport_id !== 16 &&
                      fd.teams_normalized[0].mascot}
                  </div>
                  <div className="team-record">
                    {fd.teams_normalized[0].record &&
                      fd.teams_normalized[0].record}
                  </div>
                </div>

                <div className="game-scores-result">
                  <span
                    style={
                      fd.score.score_away > fd.score.score_home ||
                      fd.score.winner_away > fd.score.winner_home
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    {fd.sport_id !== 7
                      ? fd.score.score_away
                      : fd.score.winner_away}
                  </span>
                  <span></span>
                  {/*<span>{prevData && prevData[index]?.score.score_away_by_period[prevData[index]?.score.score_away_by_period.length - 1] - eventData[index]?.score.score_away_by_period[prevData[index]?.score.score_away_by_period.length - 1]}</span>*/}
                  <span
                    style={
                      fd.score.score_home > fd.score.score_away ||
                      fd.score.winner_home > fd.score.winner_away
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    {fd.sport_id !== 7
                      ? fd.score.score_home
                      : fd.score.winner_home}
                  </span>
                </div>
                <div className="hTeam-info team-info">
                  <div className="team-name-and-ranking">
                    {!isUFC() ? (
                      <img
                        className="sportsLogo"
                        src={`https://sportslogos.nyc3.cdn.digitaloceanspaces.com/logos/${fd.teams_normalized[1].team_id}.png`}
                        alt={" Logo"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://www.escapeauthority.com/wp-content/uploads/2116/11/No-image-found.jpg";
                        }}
                      />
                    ) : (
                      <img
                        className="sportsLogo"
                        src={`https://sportslogos.nyc3.cdn.digitaloceanspaces.com/logos/${fd.teams_normalized[1].team_id}.png`}
                        alt={" Logo"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/no-profile-image.png";
                        }}
                      />
                    )}
                    <div className="team-name">
                      {fd.teams_normalized[1].name}
                    </div>
                    <div className="team-mascot">
                      {fd.sport_id !== 7 &&
                        fd.sport_id !== 11 &&
                        fd.sport_id !== 12 &&
                        fd.sport_id !== 13 &&
                        fd.sport_id !== 14 &&
                        fd.sport_id !== 15 &&
                        fd.sport_id !== 16 &&
                        fd.teams_normalized[1].mascot}
                    </div>
                    <div className="team-record">
                      {fd.teams_normalized[1].record &&
                        fd.teams_normalized[1].record}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isUFC(fd) &&
              !isMLS(fd) &&
              fd.score.score_away_by_period.length !== 0 && (
                <div className="detail-game-score away-winner">
                  <div className="normal">
                    <div className="header-periods">
                      <span className="first-cell"></span>
                      {fd.score.score_away_by_period.map((item, index) => (
                        <span className="period-number" key={index + 1}>
                          {index + 1}
                        </span>
                      ))}
                      <span className="total-number">T</span>
                    </div>
                    <div
                      className="away-score team-periods-scores"
                      style={
                        fd.score.score_away > fd.score.score_home
                          ? { color: "black" }
                          : { color: "rgb(133, 133, 133)" }
                      }
                    >
                      <span
                        className="aTeam-name-cell first-cell"
                        style={
                          fd.score.score_away > fd.score.score_home
                            ? { color: "black" }
                            : { color: "rgb(133, 133, 133)" }
                        }
                      >
                        {fd.teams_normalized[0].abbreviation}
                      </span>
                      {fd.score.score_away_by_period.map((period, index) => (
                        <span className="period-score" key={index + 1}>
                          {period}
                        </span>
                      ))}
                      <span className="total-score">{fd.score.score_away}</span>
                    </div>
                    <div
                      className="home-score team-periods-scores"
                      style={
                        fd.score.score_home > fd.score.score_away
                          ? { color: "black" }
                          : { color: "rgb(133, 133, 133)" }
                      }
                    >
                      <span
                        className="hTeam-name-cell first-cell"
                        style={
                          fd.score.score_home > fd.score.score_away
                            ? { color: "black" }
                            : { color: "rgb(133, 133, 133)" }
                        }
                      >
                        {fd.teams_normalized[1].abbreviation}
                      </span>
                      {fd.score.score_home_by_period.map((period, index) => (
                        <span key={index} className="period-score">
                          {period}
                        </span>
                      ))}

                      {fd.score.score_home_by_period.length !==
                        fd.score.score_away_by_period.length && (
                        <span className="period-score">-</span>
                      )}
                      <span className="total-score">{fd.score.score_home}</span>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div id="liveScores">
        <div
          className={`bg-calendar ${loadingMenu && "cursor-not-allowed"}`}
          style={{
            padding: "8% 0",
            backgroundImage: `linear-gradient(0deg, rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${renderSportBG()})`,
          }}
        >
          <h1 className="dateText">SELECT A DATE</h1>
          <Calendar
            onChange={onChange}
            value={value}
            tileClassName={`${loadingMenu && "cursor-not-allowed"}`}
            tileDisabled={({ activeStartDate, date, view }) =>
              loadingMenu && date
            }
            onClickDay={(value, event) => {
              setDateValue(value);
              eventsRef?.current?.scrollIntoView({ behavior: "smooth" });
              setSearchQuery("");
            }}
          />
        </div>
        <section
          id={styles.select__league}
          className={`${styles.container} mobile-only`}
        >
          <section className={styles.select__league__container}>
            <input
              className={styles.searchInput}
              type="text"
              id="search"
              placeholder="ex. Brooklyn Nets"
              value={searchQuery}
              onChange={(e) => handleChange(e)}
            />
            {!loadingMenu ? (
              <select
                id="sports"
                className={styles.dropdown}
                onChange={(e) => {
                  setSport(Number(e.target.value));
                }}
              >
                {filteredMenu.map((sport, index) => (
                  <option
                    key={sport.sport_id}
                    selected={sport.sport_id === sportId && sportId}
                    value={sport.sport_id}
                  >
                    {sport.sport_name}
                  </option>
                ))}
              </select>
            ) : (
              <div className="spinner" size={30} color="#09136a" />
            )}
          </section>
        </section>
        <section className="md-lg-only selectLeagueContainer">
          <ul>
            <li>SELECT A LEAGUE</li>
            {!loadingMenu ? (
              filteredMenu.map((sport, index) => (
                <li
                  key={sport.sport_id}
                  style={{ color: sport.sport_id === sportId && "#cc9e59" }}
                  onClick={() => [
                    setSportId(sport.sport_id),
                    setSearchQuery(""),
                  ]}
                >
                  {sport.sport_name.toUpperCase()}
                </li>
              ))
            ) : (
              <div className="spinner" size={50} color="white" />
            )}
          </ul>
          <input
            className="leagueInput md-lg-only"
            type="text"
            id="search"
            placeholder="ex. Brooklyn Nets"
            value={searchQuery}
            onChange={(e) => handleChange(e)}
          />
        </section>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
          ref={eventsRef}
        >
          {!loadingMenu && (
            <>
              <img
                className="leagueLogo"
                src={`https://sportslogos.nyc3.cdn.digitaloceanspaces.com/logos/${renderSportName(
                  sportId
                )}.svg`}
                //  src={process.env.PUBLIC_URL + `/logos/${renderSportName(sportId)}.svg`}
                alt={" Logo"}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://www.escapeauthority.com/wp-content/uploads/2116/11/No-image-found.jpg";
                }}
              />
              <h1 className="liveScoresText">
                {!isUFC(eventData) &&
                  !isNFL(eventData) &&
                  !isMLS(eventData) &&
                  !isWNBA(eventData) &&
                  !isNBA(eventData) &&
                  !isNHL(eventData) &&
                  renderSportName(sportId)}{" "}
                Live Scores (
                {searchQuery
                  ? filteredData.length === 1
                    ? `${filteredData.length} Game`
                    : `${filteredData.length} Games`
                  : eventData.length === 1
                  ? `${eventData.length} Game`
                  : `${eventData.length} Games`}
                )
              </h1>
            </>
          )}
        </div>
        <div
          className="events"
          style={{
            background: loadingData && "none",
            display: eventData.length === 0 && "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!loadingMenu && !loadingData ? (
            eventData.length !== 0 ? (
              searchQuery.length > 0 ? (
                renderFilteredGames(filteredData, prevFilteredData)
              ) : (
                renderFilteredGames(eventData, prevData)
              )
            ) : (
              <img
                src={noData}
                width={"auto"}
                height={500}
                style={{ padding: "50px" }}
                alt="no-data"
              />
            )
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
              }}
            >
              <Spinner />
              {/* <ImSpinner8 className="spinner" size={200} color="#09136a" /> */}
            </div>
          )}
        </div>
      </div>
      <StreetLines
        modal={modal}
        closeModal={closeModal}
        openModal={openModal}
      />
    </>
  );
};

export default LiveScores;
