import React from "react";
import "./Tos.scss";
import MetaTags from "react-meta-tags";
import StreetLines from "../../Components/StreetLines";
import { Link } from "react-router-dom";

const Tos = ({ openModal, modal, closeModal }) => (
  <>
    <MetaTags>
      <title>Players Circle - Terms Of Service</title>
      <meta name="description" content="Terms of Service" />
    </MetaTags>

    <section className="border-b border-solid border-gray-300 grow">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          <div className="max-w-3xl mx-auto lg:max-w-none">
            <article>
              {/* Article header */}
              {/* <header className="max-w-3xl mx-auto mb-20"> */}
              <div className="pt-32 max-w-3xl pb-12 md:pb-20 text-center md:text-left">
                <h1 className="h1">Terms of Service</h1>
              </div>
              {/* </header> */}

              {/* Article content */}
              <div className="lg:flex lg:justify-between" data-sticky-container>
                {/* Sidebar */}
                <aside className="relative hidden lg:block w-64 mr-20 shrink-0">
                  <div
                    data-sticky
                    data-margin-top="100"
                    data-sticky-for="768"
                    data-sticky-wrap
                  >
                    <h4 className="text-lg font-bold leading-snug tracking-tight mb-4">
                      Table of contents
                    </h4>
                    <ul className="font-medium -my-1">
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#services"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Services</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#registration"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Registration</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#inactivity"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Inactivity & Suspension</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#third-party"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>3rd Party Content</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#deposits"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Deposits</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#responsible-gaming"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Responsible Gaming</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#offences"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Hacking & Offences</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#acknowledgment"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Acknowledgment</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#notices"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Notices & Complaints</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#governing-law"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Governing Law</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </aside>

                {/* Main content */}
                <div>
                  {/* Article meta */}
                  <div className="flex items-center mb-6">
                    <div className="flex shrink-0 mr-3">
                      <a className="relative" href="#0">
                        <span
                          className="absolute inset-0 -m-px"
                          aria-hidden="true"
                        >
                          <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                        </span>
                        <img
                          className="relative rounded-full"
                          src={
                            "https://pbs.twimg.com/profile_images/1356027761811451906/eoCGqeKZ_400x400.jpg"
                          }
                          width="32"
                          height="32"
                          alt="Author 04"
                        />
                      </a>
                    </div>
                    <div>
                      <span className="text-gray-600">Property of </span>
                      <a
                        href="https://twitter.com/plnetworksllc"
                        target="_blank"
                        rel="noreferrer"
                        className="font-medium hover:underline"
                      >
                        PLNETWORKS SRL
                      </a>
                      <span className="text-gray-600">
                        {" "}
                        · Last Updated July 17, 2020
                      </span>
                    </div>
                  </div>
                  <hr className="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                  {/* Article body */}
                  <div className="text-lg text-gray-600">
                    <p
                      id="services"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Services
                    </p>
                    <p className="underline">
                      By visiting the Websites and/or by registering and/or
                      using the Services, you understand and agree to be, and
                      are, bound by the following:
                    </p>

                    <ul className="list-disc list-none mb-8">
                      <li className="my-3">1.1) These Terms;</li>
                      <li className="mb-3">1.2) Our Privacy Policy</li>
                      <li className="mb-3">1.3) The Betting Rules; and</li>
                      <li className="mb-3">
                        1.4) Our terms and conditions, rules or policies as they
                        relate to any applicable Services (e.g., rules for a
                        particular Game), promotions, bonuses, special offers or
                        any other condition of your use of the Services from
                        time to time and are deemed to have accepted and
                        understood all of the above.
                      </li>
                      <li className="mb-3">
                        1.5) We reserve the right to withdraw or amend the
                        Services we provide without notice. The Website is
                        updated regularly and may change at any time. You
                        acknowledge and agree that the specific form and/or
                        nature of the Website or Services may change from time
                        to time without prior notice and we may cease providing
                        the Services (or any specific features within the
                        Services) to you or to users generally at our sole
                        discretion, without prior notice to you. If the need
                        arises, we may suspend access to the Website, or close
                        it indefinitely. Any of the material on the Website may
                        be out of date at any given time, and we are under no
                        obligation to update such material. We will not be
                        liable if for any reason the Website is unavailable at
                        any time or for any period, but this shall not, of
                        itself, preclude us from returning to you any money that
                        is properly due to you. We may terminate your Account,
                        delete any content or information that you have posted
                        on the Website, and/or prohibit you from using or
                        accessing the Website or Service (or any portion, aspect
                        or feature of the Service) for any reason, at any time
                        in our sole discretion. Without limiting the generality
                        of the foregoing, we shall have the right to reject any
                        wager or withdraw any Game in our absolute discretion.
                        We will not be liable for any loss whatsoever arising
                        from the cancellation of any Game or sports wager,
                        including any realizable or perceived loss, for whatever
                        reason, and/or the chance to participate in our
                        sportsbook system.
                      </li>
                      <li className="mb-3">
                        1.6) Use of the website and associated services are
                        expressly prohibited from the following states: DE, MD,
                        NJ, NV, PA, WV, OR, IN, RI. Any account accessed from a
                        restricted state will have all access rights revoked and
                        any balance contained in the account may be considered
                        void. You are required to notify us immediately should
                        you intend to relocate to or visit a restricted state,
                        your account will then be placed on a temporary hold or
                        permanently closed depending on the circumstance with
                        any balance available being returned to you. Any attempt
                        on your part to circumvent these restrictions by any
                        means will result in your accounts closure and any
                        balance considered forfeit.
                      </li>
                      <li className="mb-3">
                        1.7) Access Disruptions. We are not responsible or
                        liable in any way for any Internet disruptions or
                        disconnections, and all risk and responsibility rests
                        with you.
                      </li>
                    </ul>

                    <p
                      id="registration"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Registration
                    </p>

                    <ul className="list-disc list-none mb-8">
                      <li className="my-3">
                        2) Account registration: To use the Services, you will
                        first need to register for an account with Us. You may
                        access any of the Services from Your Account. You are
                        prohibited from allowing any other person to access or
                        use Your Account.
                      </li>
                      <li className="mb-3">
                        2.1) In consideration of your use of this service you
                        represent that you are of legal age to form a legal
                        binding contract and are at least 21 years old. We
                        reserve the right to suspend your account and request
                        proof of age in order to ensure that minors are not
                        using the service. If proof of age isn’t provided, we
                        reserve the right to cancel and forfeit your account
                        indefinitely.
                      </li>
                      <li className="mb-3">
                        2.2) In order to open an account with Us you must
                        provide the following information: A unique account name
                        and password Your full legal name Your social security
                        number or legal identification number (based on your
                        country residency) Your physical address, A valid email
                        address, Birth date Telephone number If your identity
                        cannot be validated, you may be required to submit
                        additional information or documentation. You will also
                        be required to answer one or more security questions,
                        after which you will be sent a confirmation via email.
                        You may also be required to update the information or
                        data or to provide additional items as part of ongoing
                        efforts to prevent illegal and fraudulent activities,
                        and/or to comply with our Anti-Money Laundering policies
                        and protocols.
                      </li>
                      <li className="mb-3">
                        2.3) All information and data that you provide to us
                        either at the time you register for an Account or at any
                        subsequent time must be truthful, accurate and
                        verifiable in all respects. By providing such
                        information and data you consent to us submitting it to
                        third party providers of age and identification services
                        to verify that you are who you say you are and that the
                        information you give is true and accurate. We reserve
                        the right to use third party verification services to
                        authenticate your account information and identity, and
                        you expressly acknowledge and agree that we may confirm
                        the accuracy of any information you submit against
                        government-issued ID.
                      </li>
                      <li className="mb-3">
                        2.4) All payments to and from Your Account must be paid
                        in U.S. dollars and shall not bear interest and You
                        shall ensure that all payments into Your Account are
                        from a payment source for which You are the named
                        account holder.
                      </li>
                      <li className="mb-3">
                        2.5) You may log in to your Account using your email
                        address and password. You must treat your user email
                        address and password as confidential and not disclose
                        any part of them to anyone else. We have the right to
                        disable any user Account, password whether chosen by you
                        or assigned by us, at any time, if you have failed to
                        comply with any of the provisions of these Terms of
                        Service. You are responsible for all transactions that
                        take place on your Account and so are deemed liable for
                        all bets whether you consent to having made such
                        transactions.
                      </li>
                    </ul>

                    <p
                      id="inactivity"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Inactivity and Account Suspension
                    </p>

                    <ul className="list-disc list-none mb-8">
                      <li className="my-3">
                        3.0) Your account will be suspended after 12 months of
                        no activity. Activity may include but not limited to
                        deposits, withdrawals, or bet/wager placed. All balances
                        will be deemed forfeit and any funds remaining will be
                        considered lost.
                      </li>
                      <li className="mb-3">
                        3.1) You can request withdrawals from Your Account at
                        any time provided all payments made have been received.
                        We reserve the right to pay any requested withdrawal
                        partly or in total via the same method of payment and in
                        the same currency with which deposits were made. When
                        using credit/debit card to make a deposit, we may elect
                        not to accept any withdrawal request within fourteen
                        (14) days after the deposit.
                      </li>
                      <li className="mb-3">
                        3.2) No bet will be acceptable until a deposit is made
                        and cleared by our internal accounting department.
                      </li>
                      <li className="mb-3">
                        3.3) Any deposit made must be cleared prior to
                        bets/wagers considered valid.
                      </li>
                      <li className="mb-3">
                        3.4) In order to use the Services, You will be required
                        to send money to and may be required to receive money
                        from Us. We may use third-party electronic payment
                        processors and/or financial institutions (FI) to process
                        such financial transactions. You irrevocably authorize
                        Us, as necessary, to instruct such (FI) to handle
                        Account deposits and withdrawals from Your Account and
                        You irrevocably agree that We may give such instructions
                        on Your behalf in accordance with Your requests as
                        submitted using the relevant feature on Our Platforms.
                        You agree to be bound by the terms and conditions of use
                        of each applicable FI. In the event of conflict between
                        these Agreements and the FI's terms and conditions then
                        these Agreements shall prevail. In the event We use such
                        financial institutions to process payments made by and
                        to You, or otherwise accept Your use of any particular
                        payment method, in connection with Your use of the
                        Services, We shall have no responsibility for the acts
                        or omissions of the third party providing such payment
                        processing or payment method prior to Our receipt of
                        funds or after We initiate a transfer of funds (as
                        applicable). You agree that You shall look exclusively
                        to the FI or financial institution in the event of any
                        payment processing or other payment method related
                        disputes and not to Us.
                      </li>
                      <li className="mb-3">
                        3.6) Bonuses: We may from time to time offer You
                        complimentary or bonus amounts to be credited by Us into
                        Your Account ('Bonus(es)'). Such Bonuses may only be
                        used in relation to such Services as may be specified
                        when the Bonus is offered to You. Acceptance of any
                        Bonus shall be in accordance with additional terms and
                        conditions We may make available to You in respect of
                        each such Bonus offering and, if none, then in
                        accordance with the Standard Promotional Terms and
                        Conditions and bonus release restrictions contained in
                        the relevant offer. Offers may be used only ONCE unless
                        otherwise specified.
                      </li>
                      <li className="mb-3">
                        3.7) Withdrawal of Bonus funds, You are not entitled to
                        withdraw any Bonus amounts and You may not remove any
                        cash obtained via a Bonus from Your Account without
                        first complying with the applicable terms including,
                        without limitation, in respect of any qualifiers or
                        restrictions.
                      </li>
                      <li className="mb-3">
                        3.8) We may report and withhold any amount from Your
                        winnings in order to comply with any applicable law. All
                        taxes due in connection with any winnings awarded to You
                        are Your sole liability. Account balances cannot be
                        transferred, substituted or redeemed for any other
                        prize. Payment of funds which You withdraw shall be made
                        by check, wire, credit card, Crypto-Currency and/or any
                        other manner which We select in Our sole discretion,
                        although We will try to accommodate Your preferences as
                        indicated by You when You register.
                      </li>
                      <li className="mb-3">
                        3.9) Payments will be made as soon as reasonably
                        possible (subject to up to five business days due to
                        internal processing time).
                      </li>
                    </ul>

                    <p
                      id="third-party"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Third Party Content
                    </p>

                    <ul className="list-disc list-none mb-8">
                      <li className="my-3">
                        4.0) Third party content, Abusive or offensive language
                        will not be tolerated on Our chat boards, or otherwise
                        by You on the Platforms, Services, or with Sportsbook
                        staff. In addition, You are not entitled to make untrue
                        and/or malicious and/or damaging comments with regard to
                        the Sportsbook’s operation in any media or forum.
                      </li>
                      <li className="mb-3">
                        4.1) We may reject or delete any text, files, images,
                        photos, video, sounds, or any other materials posted by
                        You on the Platforms which in Our sole opinion breaches
                        the terms of these Agreements.
                      </li>
                      <li className="mb-3">
                        4.2) Any violation of this policy may result in removal
                        of the Third-Party Content, a suspension of Your use of
                        the Services and/or such other action as may be
                        reasonably required by Us to ensure compliance.
                      </li>
                    </ul>

                    <p
                      id="deposits"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Deposits
                    </p>

                    <ul className="list-disc list-none mb-8">
                      <li className="my-3">
                        5.0) We reserve the right to place a limit on the amount
                        of funds that you will be able to deposit at any time at
                        a level that we may determine at our sole discretion.
                        You may request that this deposit limit be altered by
                        contacting our Customer Services Team.
                      </li>
                      <li className="mb-3">
                        5.1) Neither we nor any of our service providers shall
                        be under any obligation to accept your Deposit. We shall
                        notify you if your Deposit is rejected. If your Deposit
                        is accepted, we shall ensure that funds equal to those
                        specified in your Deposit are credited to your Account
                        within a reasonable period.
                      </li>
                      <li className="mb-3">
                        5.2) If any deposit is charged back or is otherwise
                        uncollectible for any reason, any and all winnings
                        generated from play or bets conducted in such Account
                        from the time of the applicable deposit until its
                        reversal or un-collectability shall be invalidated,
                        forfeited and deducted from your Account balance.
                      </li>
                      <li className="mb-3">
                        5.3) Criminal Activity. If we have reasonable cause to
                        believe that criminal or other suspicious activities are
                        occurring through one or more Accounts (including,
                        without limitation, a suspicion of attempted
                        money-laundering or fraud), we expressly reserve the
                        right to close such Accounts and/or report such activity
                        to the authorities, or any other applicable regulatory
                        bodies or services. All Account balances (including both
                        deposits and any winnings) shall be forfeited.
                      </li>
                      <li className="mb-3">
                        5.4) Fraudulent Activity. If, in our reasonable
                        discretion, we determine that a player has engaged in
                        fraudulent, unlawful, dishonest or improper activity
                        (including, without limitation, the usage of a VPN,
                        proxy or similar service that masks or manipulates the
                        identification of your real location, or by otherwise
                        providing false or misleading information, or by making
                        bets, wagers through a third party or on behalf of a
                        third party) we reserve the right to immediately
                        terminate or deny a player access to their Account. In
                        such event, all Account balances (including both
                        deposits and any winnings) shall be forfeited and we
                        reserve the right to disclose information (including the
                        identity of the player) to applicable parties including
                        but not limited to banks, credit card companies and/or
                        any person or entity that has the legal right to such
                        information, and/or taking legal action against such
                        player.
                      </li>
                      <li className="mb-3">
                        5.5) System Vulnerability. If, in our reasonable
                        discretion, we determine that an Account sought to or
                        actually did exploit any hardware or software error,
                        malfunction, "bug" or other vulnerability, we shall
                        immediately close such Account and all Account balances,
                        including both deposits and any winnings, shall be
                        immediately forfeited. In such event, we expressly
                        reserve the right to initiate civil legal proceedings
                        and report such activities to authorities in support of
                        criminal investigations and charges, as appropriate.
                      </li>
                      <li className="mb-3">
                        5.6) We have a zero-tolerance policy towards
                        inappropriate play and fraudulent activity. If, in Our
                        sole determination, You are found to have cheated or
                        attempted to defraud Us and/or the Sportsbook or any
                        other user of any of the Services in any way, including
                        but not limited to game manipulation or payment fraud,
                        manipulation of the multi-currency facilities, betting
                        on all possible outcomes of a game or event or if We
                        suspect You of fraudulent payment, including use of
                        stolen credit cards, or any other fraudulent activity
                        (including but not limited to any chargeback or other
                        reversal of a payment) or prohibited transaction
                        (including but not limited to money laundering) or if
                        Your deposits failed to be honored by Your bank for any
                        reason, We reserve the right to suspend and/or close
                        Your Account and recover bad debts using whichever
                        method may lawfully be available to Us including, but
                        not limited to, (i) debiting the amount owed by You from
                        Your Account; and (ii) instructing third party
                        collections agencies to collect the debt. This may have
                        a detrimental impact on Your credit rating and will
                        require Us to share Your personal information (including
                        Your identity) with appropriate agencies and to report
                        any criminal or suspicious activities to the appropriate
                        authorities. We reserve the right to void and withhold
                        any or all winnings made by any person and to void and
                        withhold any Bonus Points gained by any person or where
                        We have reasonable grounds to believe that said person
                        is acting or has acted in liaison in an attempt to
                        defraud or damage Us and/or the Sportsbook and/or the
                        Services and/or the Platforms in any way. In the
                        interests of data protection, security and avoidance of
                        fraud We do not permit use of any communication channels
                        included within the Services and/or the Platforms
                        (including but not limited to chat boards, Twitter,
                        Facebook or Snapchat) to offer or promote any offers,
                        products and services (whether Yours or a third
                        party's). You are expressly prohibited from posting
                        information or contacting Our customers to offer or
                        promote any offers, products or services.
                      </li>
                      <li className="mb-3">
                        5.7) We reserve the right to declare null and void any
                        wagers or bets that were the subject of such Error and
                        to take any money from Your Account relating to the
                        relevant bets or wagers, if there are insufficient funds
                        in Your Account, We may demand that You pay Us the
                        relevant outstanding amount relating to these bets or
                        wagers. In all circumstances whereby We (in Our sole
                        discretion) determine an Error has been used to gain an
                        unfair advantage, We reserve the right to consider this
                        activity to be subject to Forfeiture & Account Closure.
                      </li>
                    </ul>

                    <p
                      id="responsible-gaming"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Responsible Gaming
                    </p>

                    <ul className="list-disc list-none mb-8">
                      <li className="my-3">
                        6.0) We take Responsible Gaming seriously. No Underage
                        Access / Play. Without limiting any other provision
                        hereof, and in the interests of clarity, under no
                        circumstances may an account be created by any user that
                        is under the age of Twenty one(21), nor shall anyone
                        under the age of Twenty one(21) be permitted to place
                        wagers. Any winnings in an Account that is identified by
                        us as having been improperly opened or used by someone
                        under the age of 21 shall be forfeited and the Account
                        closed.
                      </li>
                      <li className="mb-3">
                        6.1) The Website and the Services are intended solely
                        for recreational, entertainment purposes. We are
                        committed to ensuring that players can manage or limit
                        their play on the Website. If gambling is negatively
                        affecting your personal or professional life, please
                        refer to the resources available here to seek counsel or
                        assistance, www.gamblersanonymous.org,
                        www.responsiblegaming.com or call 800.gambler
                      </li>
                      <li className="mb-3">
                        6.2) Self-Administered Limit Controls. We maintain
                        processes for you to manage and limit your deposits on
                        the Website. You may limit your deposits on a monthly
                        basis. Note that the deposit limit will apply as an
                        aggregate limit for all deposit instruments attached to
                        the Account at the time of the limit request only. Note
                        further that if you self-impose a monthly deposit limit
                        and later wish to increase or remove such limit, such
                        removal or increase will not take place until at least
                        24 hours following the limit increase request.
                      </li>
                      <li className="mb-3">
                        6.3) Setting Deposit Limit to Zero / Account Closure due
                        to Problem Gambling. You have the option of setting your
                        play limits to zero or completely closing the Account if
                        you believe you have a gambling problem. We honor all
                        such directions immediately. Please note that when
                        contacting Customer Service to implement such measures,
                        you must identify that you are requesting an Account
                        closure or deposit limit due to a gambling problem. If
                        you request to have your Account closed or your Account
                        limit set to zero because of a perceived gambling
                        problem, please be advised that your Account will be
                        permanently closed without the option to re-open.
                      </li>
                      <li className="mb-3">
                        6.4) Temporarily Setting on "Freeze" Option. You have
                        the option of setting your wagering limits to zero by
                        checking “Freeze” option in your profile to allow you to
                        "Freeze" or to otherwise manage your play patterns. Note
                        that the wagering limit will apply as an aggregate limit
                        for all wagers at the time of the limit request only.
                        Unchecking the “Freeze” option will not be implemented
                        until 24 hours following the receipt of such request.
                      </li>
                    </ul>

                    <p
                      id="offences"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Viruses, Hacking and Other Offences
                    </p>

                    <ul className="list-disc list-none mb-8">
                      <li className="my-3">
                        7.0) System Hacks or Vulnerability Attacks, we will
                        enforce a zero tolerance on anyone attempting to bypass
                        any security in place. As such we will prosecute any and
                        all individuals with full force and legal
                        representations along with government assistance to
                        assure you are condemned in full for your actions. Any
                        attempt to gain an advantage via use of Hacks, Viruses,
                        Bots, or other form of attack to any of our servers or
                        website will be punishable by Law and will incur
                        punitive damages and prison time.
                      </li>
                      <li className="mb-3">
                        7.1) System or Human errors will deem all wagers
                        involving such errors as no action and wagers will be
                        refunded to appropriate accounts.
                      </li>
                      <li className="mb-3">
                        7.2) Line errors will also be deemed no action and
                        wagers will be refunded to appropriate accounts. WE DO
                        NOT PAY ANY ERRORS WHATSOEVER!
                      </li>
                      <li className="mb-3">
                        7.4) You must not misuse the Website by knowingly
                        introducing viruses, trojans, worms, logic bombs or
                        other material which is malicious or technologically
                        harmful. You must not attempt to gain unauthorized
                        access to the Website, the server(s) on which the
                        Website is stored, or any server, computer or database
                        connected to the Website. You must not attack the
                        Website via a denial-of-service attack or a distributed
                        denial-of service attack. By breaching this provision,
                        you would commit a criminal offence. We will report any
                        such breach to the relevant law enforcement authorities,
                        and we will co-operate with those authorities by
                        disclosing your identity to them. In the event of such a
                        breach, your right to use the Website will cease
                        immediately and you shall immediately forfeit all
                        amounts in your Account as liquidated damages incurred
                        because of such attack
                      </li>
                      <li className="mb-3">
                        7.5) We will not be liable for any loss or damage caused
                        by a distributed denial-of-service attack, viruses or
                        other technologically harmful material that may infect
                        your computer equipment, computer programs, data or
                        other proprietary material due to your use of the
                        Website or to your downloading of any material posted on
                        it, or on any website linked to it.
                      </li>
                    </ul>

                    <p
                      id="acknowledgment"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Acknowledgment
                    </p>

                    <ul className="list-disc list-none mb-8">
                      <li className="my-3">
                        8.1) YOU HAVE VOLUNTARILY SOUGHT OUT AND ESTABLISHED
                        CONTACT WITH THE WEBSITE;
                      </li>
                      <li className="mb-3">
                        8.2) YOU ARE SOLELY RESPONSIBLE FOR CONFIRMING AND
                        ENSURING YOUR COMPLIANCE WITH ANY LOCAL PROHIBITIONS
                        AND/OR REGULATIONS THAT MAY BE APPLICABLE TO YOUR
                        ACTIVITIES ON THE WEBSITE, INCLUDING THOSE OF YOUR
                        COUNTRY OR JURISDICTION OF RESIDENCE.
                      </li>
                      <li className="mb-3">
                        8.3) YOU ARE SOLELY RESPONSIBLE FOR ANY AND ALL LOCAL
                        TAXES OR OTHER AMOUNTS PAYABLE IN YOUR RESIDENT COUNTRY
                        OR OTHERWISE RELATING TO ANY WINNINGS OR WITHDRAWALS
                        MADE BY YOU IN RELATION TO YOUR ACCOUNT. INTELLECTUAL
                        PROPERTY RIGHTS
                      </li>
                      <li className="mb-3">
                        9.1) You acknowledge that we the Sportsbook are the sole
                        owner or licensee of all Intellectual Property Rights on
                        this Website, the Content, Software, and the Games.
                        Those works are protected by intellectual laws and
                        treaties around the world. All such rights are reserved.
                        For the purposes of these Terms, "Intellectual Property
                        Rights" means patent rights (including patent
                        applications and disclosures), copyrights (including
                        copyright applications), trade secrets, moral rights,
                        know-how and any other similar rights or intangible
                        assets recognized under any law(s) or international
                        convention(s) in any country or jurisdiction in the
                        world where such rights accrue.
                      </li>
                    </ul>

                    <p
                      id="notices"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Notices and Complaints
                    </p>

                    <ul className="list-disc list-none mb-8">
                      <li className="my-3">
                        10) If You have any complaints, claims or disputes with
                        regard to any alleged winnings, alleged losses or the
                        award or distribution of cash, prizes, benefits, tickets
                        or any other item or items in a game, tournament,
                        contest, drawing, promotion or similar activity or
                        event, or the manner in which a game, tournament,
                        contest, drawing, promotion or similar activity or event
                        is conducted regarding the Services, You must submit
                        Your complaint to Us in writing as soon as is reasonably
                        practicable following the date of the original
                        transaction to which the claim or dispute refers.
                        Complaints may be submitted by email to INFO@domain.com.
                        Any notice We give to You (save as otherwise set out
                        herein) will be sent to the email address that You
                        provide when You register Your Account. It is Your
                        responsibility to give Us notice of any changes to this
                        address through the 'Change Email' facility in Our
                        software and to regularly check Your email account for
                        emails from Us. To the extent that You are not satisfied
                        with Our response You may contact US requesting further
                        investigation to the matter which will be escalated to a
                        supervisor.
                      </li>
                    </ul>

                    <p
                      id="governing-law"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Governing Law
                    </p>

                    <ul className="list-disc list-none mb-8">
                      <li className="my-3">
                        11) These Agreements shall be governed by and construed
                        in accordance with the laws of CURACAO. You irrevocably
                        agree to submit to the exclusive jurisdiction of the
                        courts of curacao for settlement of any disputes or
                        matters arising out of or concerning these Agreements or
                        their enforceability. If any part of these Agreements is
                        found to be invalid, illegal or unenforceable in any
                        respect, it will not affect the validity of the
                        remainder of the Agreements, which shall remain valid
                        and enforceable according to their terms.
                      </li>
                    </ul>

                    {/* end of content */}
                    <div>
                      <hr className="w-full h-px pt-px mt-8 bg-gray-200 border-0" />
                      <div className="mt-8">
                        Have any comments, questions or concerns?{" "}
                        <Link
                          to="/contact-us"
                          className="text-gray-900 underline"
                        >
                          Reach out
                        </Link>{" "}
                        to let us know.
                      </div>
                      <div className="mt-6">
                        <Link
                          to="/"
                          className="inline-flex items-center text-base text-blue-600 font-medium hover:underline"
                        >
                          <svg
                            className="w-3 h-3 fill-current text-blue-400 shrink-0 mr-2"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M.293 5.282L5 .5l1.414 1.436-3 3.048H12v2.032H3.414l3 3.048L5 11.5.293 6.718a1.027 1.027 0 010-1.436z" />
                          </svg>
                          <span>Back to the Home</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Article footer */}
            </article>
          </div>
        </div>
      </div>
      <StreetLines
        modal={modal}
        closeModal={closeModal}
        openModal={openModal}
      />
    </section>
    {/* <StreetLines modal={modal} closeModal={closeModal} openModal={openModal} /> */}
  </>
);

export default Tos;
