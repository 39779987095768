import React, {useEffect} from "react";

function LineRow({handleTotalPayout, handleInputChange, handleAddClick, handleRemoveClick, inputList}) {
  useEffect(() => {
    handleTotalPayout(inputList.reduce((totalPayout, value) => totalPayout * value.payout, 1));
    // eslint-disable-next-line
  }, [inputList]);
  // const inputEl = useRef(null);

  useEffect(() => {
    if (inputList[inputList.length - 1].oddsRef) {
      inputList[inputList.length - 1].oddsRef.focus();
    }
  }, [inputList]);


  // function handleFocus() {
  //   inputEl?.current?.select();
  // }

  // console.log(odds)

  return (
    <div className="bet__calculator__input__container">
      {inputList.map((x, i) => {
        return (
          <div className="bet__calculator__line" key={i + 1}>
            <span className="bet__calculator__span">{i + 1}.</span>
            <input
              name="payout"
              className="bet__calculator__input"
              placeholder="Enter Payout"
              value={x.payout}
              readOnly
              disabled
              onChange={e => handleInputChange(e, i)}
            />
            <input
              className="bet__calculator__input"
              style={{border: Number(x.odds) === 0 && '1px solid red'}}
              name="odds"
              // type="number"
              inputMode="numeric"
              ref={e => x.oddsRef = e}
              // onFocus={handleFocus}
              placeholder="Enter Odds"
              value={x.odds}
              onChange={e => handleInputChange(e, i)}
            />
            {/*{inputList.length - 1 !== i &&*/}
            <div className="btn-box">
              {inputList.length > 1 && <span style={{cursor: "pointer"}} onClick={() => handleRemoveClick(i)}>🗑️</span>}
            </div>
            {/*}*/}
          </div>
        );
      })}
      {inputList.length < 12 && <button className="text-[#0f2369] border border-solid border-[#0f2369] text-md bg-transparent flex justify-center items-center cursor-pointer hover:bg-[#0f2369] hover:text-white rounded p-1 w-full my-2" onClick={handleAddClick}
                                        disabled={inputList[inputList.length - 1]?.odds === 0}>+
        Add additional
        line
      </button>}
    </div>
  );
}

export default LineRow;