import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import styles from "./VideosScreen.module.scss";
import Banner from "../../Assets/images/promotional-banner-footer.png";
// import footerStyles from "../Home/HomeScreen.module.scss";
import StreetLines from "../../Components/StreetLines";
import { Link } from "react-router-dom";

const VideosScreen = ({ modal, openModal, closeModal }) => {
  const [selectedFilter, setSelectedFilter] = useState("All");
  //testing url

  const urls = [
    {
      link: "617532023",
      fullLink:
        "https://player.vimeo.com/video/617532023?h=f2f723139c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      title: "Navigating Player Circle",
      duration: "3 min",
      tags: [{ name: "Guides", color: "bg-blue-500", hover: "bg-blue-600" }],
      description: "Video demonstrates Player Circle website",
    },
    {
      link: "583167100",
      title: "Registering Your Account",
      duration: "1 min",
      tags: [{ name: "Tutorial", color: "bg-red-500", hover: "bg-red-600" }],
      description: "How to Register and Create your User Account",
    },
    {
      link: "584062857",
      title: "Funding Your Account",
      duration: "1 min and 30 sec",
      tags: [{ name: "Guides", color: "bg-blue-500", hover: "bg-blue-600" }],
      description: "This video teaches you how to fund your balance",
    },
    {
      link: "589658932",
      title: "How To Place A Bet",
      duration: "1 min",
      tags: [{ name: "Tutorial", color: "bg-red-500", hover: "bg-red-600" }],
      description:
        "This video demonstrates how to place a wager on multiple bet types",
    },
    {
      link: "589658932",
      fullLink:
        "https://player.vimeo.com/video/632062625?h=c1d30049c9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      title: "How To Withdraw",
      duration: "1 min",
      tags: [{ name: "Tutorial", color: "bg-red-500", hover: "bg-red-600" }],
      description:
        "This video demonstrates the steps to withdraw from your current balance",
    },
  ];

  let filteredArray = urls
    .filter((element) =>
      element.tags.some((subElement) => subElement.name === selectedFilter)
    )
    .map((element) => element);
  console.log("HERE", filteredArray);
  const renderFilteredVideos = (array) => {
    if (selectedFilter === "All") {
      filteredArray = urls;
    }
    return filteredArray.map((url) => {
      const { tags, title, description, duration, fullLink, link } = url;
      return (
        <article className="flex flex-col h-full" data-aos="zoom-y-out">
          <header>
            <a href="#" className="block mb-6">
              <figure className="relative pb-9/16 overflow-hidden translate-z-0 rounded">
                {/* <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" src={TutorialImage01} width="352" height="198" alt="News 01" /> */}
                <div class="aspect-w-16 aspect-h-9">
                  <iframe
                    src={ fullLink ? fullLink : `https://player.vimeo.com/video/${link}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;byline=false;`}
                    className="w-full"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </figure>
            </a>
            <div className="mb-3">
              <ul className="flex flex-wrap text-xs font-medium -m-1">
                {tags.map((tag, index) => {
                  return (
                    <li className="m-1">
                      <a
                        className={`inline-flex text-center text-gray-100 py-1 px-3 rounded-full ${tag.color} hover:${tag.hover} transition duration-150 ease-in-out`}
                        href="#0"
                      >
                        {tag.name}
                      </a>
                    </li>
                  );
                })}

                <li className="m-1">
                  <span className="inline-flex text-center bg-gray-800 text-gray-100 py-1 px-3 rounded-full bg-white shadow-sm">
                    {duration}
                  </span>
                </li>
              </ul>
            </div>
            <h3 className="text-xl font-bold leading-snug tracking-tight mb-2">
              <Link to="/blog-post" className="hover:underline">
                {title}
              </Link>
            </h3>
          </header>
          <p className="text-gray-600 grow">{description}</p>
          <footer className="text-sm flex items-center mt-4">
            <div className="flex shrink-0 mr-3">
              <a className="relative" href="#0">
                <span className="absolute inset-0 -m-px" aria-hidden="true">
                  <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                </span>
                <img
                  className="relative rounded-full"
                  src={
                    "https://pbs.twimg.com/profile_images/1356027761811451906/eoCGqeKZ_400x400.jpg"
                  }
                  width="32"
                  height="32"
                  alt="Author 01"
                />
              </a>
            </div>
            <div>
              <span className="text-gray-600">By </span>
              <a
                className="font-medium hover:underline"
                href="https://twitter.com/plnetworksllc"
                rel="noreferrer"
                target="_blank"
              >
                PLNETWORKS SRL
              </a>
            </div>
          </footer>
        </article>
      );
    });
  };

  return (
    <>
      <MetaTags>
        <title>Players Circle - Tutorials</title>
        <meta name="description" content="Video Tutorials" />
      </MetaTags>
     
      <section className="relative">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pb-20">
            {/* Page header */}
            <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
              <h1 className="h1">
                Tutorials & Guides
              </h1>
            </div>

            {/* Section tags */}
            <div className="border-b border-gray-300 border-solid pb-4 mb-12">
              <ul className="flex flex-wrap justify-center md:justify-start font-medium -mx-5 -my-1">
                <li
                  className="mx-5 my-1"
                  onClick={() => setSelectedFilter("All")}
                >
                  <a
                    className={`${
                      selectedFilter === "All"
                        ? "text-blue-600"
                        : "text-gray-800"
                    } hover:underline`}
                    href="#0"
                  >
                    All
                  </a>
                </li>
                <li
                  className="mx-5 my-1"
                  onClick={() => setSelectedFilter("Tutorial")}
                >
                  <a className={`${
                      selectedFilter === "Tutorial"
                        ? "text-blue-600"
                        : "text-gray-800"
                    } hover:underline`} href="#0">
                    Tutorials
                  </a>
                </li>
                {/* <li className="mx-5 my-1" onClick={() => setSelectedFilter("")}>
                  <a className={`${
                      selectedFilter === "All"
                        ? "text-blue-600"
                        : "text-gray-800"
                    } hover:underline`} href="#0">
                    Tips & Tricks
                  </a>
                </li>
                <li className="mx-5 my-1" onClick={() => setSelectedFilter("Guides")}>
                  <a className={`${
                      selectedFilter === "All"
                        ? "text-blue-600"
                        : "text-gray-800"
                    } hover:underline`} href="#0">
                    Free ebooks
                  </a>
                </li> */}
                <li
                  className="mx-5 my-1"
                  onClick={() => setSelectedFilter("Guides")}
                >
                  <a className={`${
                      selectedFilter === "Guides"
                        ? "text-blue-600"
                        : "text-gray-800"
                    } hover:underline`} href="#0">
                    Guides
                  </a>
                </li>
              </ul>
            </div>

            {/* Articles list */}
            <div className="max-w-sm mx-auto md:max-w-none">
              {/* Articles container */}
              <div className="grid gap-12 md:grid-cols-2 lg:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
                {renderFilteredVideos(urls)}
                {/* 1st article */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <StreetLines
        openModal={openModal}
        modal={modal}
        closeModal={closeModal}
      />
    </>
  );
};

export default VideosScreen;
