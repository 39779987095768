import React from "react";
import { FaSync } from "react-icons/fa";

const Tooltip = ({ setFetchedNews, fetchedNews }) => {

  return (
    <div
      className="relative flex flex-col items-center group shadow-lg cursor-pointer"
      onClick={() => setFetchedNews(true)}
    >
      <FaSync className="text-xl hidden lg:flex text-[#091368]"/>
      <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
        <span className="relative z-10 p-2 text-sm leading-none text-black whitespace-no-wrap bg-white w-96">
          Internet issues? Click to refresh!
        </span>
        {/* <span className="relative z-10 p-2 text-xs leading-none text-black whitespace-no-wrap bg-white w-96">Fetch News Testing how much you can</span> */}
        <div className="w-3 h-3 -mt-2 rotate-45 bg-white"></div>
      </div>
    </div>
  );
};

export default Tooltip;
