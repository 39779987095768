import React, { useRef, useEffect } from "react";
import "./CurrentSportsLines.scss";
import moment from "moment";
import NoData from "../../Assets/images/no-data.jpg";
// import {Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const CurrentSportsLines = ({
  currentLines,
  currentSport,
  searchQuery,
  setHalf,
  half,
  backgroundColor,
  color,
  closeModal,
}) => {
  const filteredLines = currentLines.filter(
    (line) =>
      line.AwayTeam_Desc.toLowerCase().includes(searchQuery.toLowerCase()) ||
      line.HomeTeam_Desc.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const prevLinesRef = useRef();
  useEffect(() => {
    prevLinesRef.current = filteredLines;
  });

  // console.log("prev", prevLinesRef.current, "current", filteredLines);

  // I need a function that will take two params, previousLines and currentLines,
  // it should return a ternary of this structure in example below this is for styles
  // it should also find the previous line from the array of currentLines

  // const compareLines = (prevLines, currentLines) => {
  //     currentLines.find(prevLines => {
  //         console.log(prevLines);
  //     })
  // }

  // compareLines(prevLinesRef, filteredLines);
  // prevLine.JAwayMoney === currentLine.JAwayMoney ? {color: black} : {color: green}
  const headings = [
    `${currentSport} - Teams`,
    // 'Id',
    "ML",
    "Spread",
    "Total Pts",
    half ? "H1 ML" : "H2 ML",
    half ? "H1 Spread" : "H2 Spread",
    half ? "H1 Pts" : "H2 Pts",
  ];

  const awayContent = (line) => [
    <span style={{ color: backgroundColor }}>{line?.J_AwayMoney}</span>,
    <span style={{ color: backgroundColor }}>
      {line?.J_AwaySpread} ({line?.J_AwayPrice})
    </span>,
    <span style={{ color: backgroundColor }}>
      {line?.J_TotalPoints} O {line?.J_OverPrice}
    </span>,
    <span style={{ color: backgroundColor }}>
      {half ? line?.H1_AwayMoney : line?.H2_AwayMoney}
    </span>,
    <span style={{ color: backgroundColor }}>
      {half ? line?.H1_AwaySpread : line?.H2_AwaySpread} (
      {half ? line?.H1_AwayPrice : line?.H2_AwayPrice})
    </span>,
    <span style={{ color: backgroundColor }}>
      {half ? line?.H1_TotalPoints : line?.H2_TotalPoints} O{" "}
      {half ? line?.H1_OverPrice : line?.H2_OverPrice}
    </span>,
  ];

  const homeContent = (line) => [
    <span style={{ color }}>{line?.J_HomeMoney}</span>,
    <span style={{ color }}>
      {line?.J_HomeSpread} ({line?.J_HomePrice})
    </span>,
    <span style={{ color }}>
      {line?.J_TotalPoints} U {line?.J_UnderPrice}
    </span>,
    <span style={{ color }}>
      {half ? line?.H1_HomeMoney : line?.H2_HomeMoney}
    </span>,
    <span style={{ color }}>
      {half ? line?.H1_HomeSpread : line?.H2_HomeSpread} (
      {half ? line?.H1_HomePrice : line?.H2_HomePrice})
    </span>,
    <span style={{ color }}>
      {half ? line?.H1_TotalPoints : line?.H2_TotalPoints} U{" "}
      {half ? line?.H1_UnderPrice : line?.H2_UnderPrice}
    </span>,
  ];

  const mapHeadings = (headings) =>
    headings.map((heading, i) => <span key={i}>{heading}</span>);

  const renderTableHeadings = () => mapHeadings(headings);

  const mapContent = (content) =>
    content.map((cont, i) => <span key={i}>{cont}</span>);

  const renderAwayTeamsContent = (line) => mapContent(awayContent(line));

  const renderHomeTeamsContent = (line) => mapContent(homeContent(line));

  const mapLines = (lines) =>
    lines.length === 0 ? (
      <section className="current__sports__lines__no__data__container">
        <img
          className="current__sports__lines__no__data"
          src={NoData}
          alt=""
          width="100%"
          height="auto"
        />
      </section>
    ) : (
      <section className="current__sports__lines__table__content__container">
        {lines
          .sort((a, b) =>
            a.StartDateTime > b.StartDateTime
              ? 1
              : b.StartDateTime > a.StartDateTime
              ? -1
              : 0
          )
          .map((line) => (
            <section
              className="current__sports__lines__table__content"
              key={line.Game_Num}
            >
              <section className="current__sports__lines__team__1">
                <span>
                  {line.League !== "BOX" && (
                    <img
                      className="current__sports__logo"
                      src={`https://sportslogos.nyc3.cdn.digitaloceanspaces.com/images/${line.AwayTeam_Num}.png`}
                      alt={line.AwayTeam_Desc + " Logo"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://www.escapeauthority.com/wp-content/uploads/2116/11/No-image-found.jpg";
                      }}
                    />
                  )}
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: line.AwayTeam_Desc.toString(),
                    }}
                  />
                </span>
                {/* <span>{line.AwayTeam_Num}</span> */}
                {renderAwayTeamsContent(line)}
              </section>
              <section className="current__sports__lines__team__2">
                <span>
                  {line.League !== "BOX" && (
                    <img
                      className="current__sports__logo"
                      src={`https://sportslogos.nyc3.cdn.digitaloceanspaces.com/images/${line.HomeTeam_Num}.png`}
                      alt={line.HomeTeam_Desc + " Logo"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://www.escapeauthority.com/wp-content/uploads/2116/11/No-image-found.jpg";
                      }}
                    />
                  )}
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: line.HomeTeam_Desc.toString(),
                    }}
                  />
                </span>
                {/* <span>{line.HomeTeam_Num}</span> */}
                {renderHomeTeamsContent(line)}
              </section>
              <section className="current__sports__lines__start__time">
                <p>
                  {moment(line.StartDateTime, "YYYYMMDD, HH:mm:ss").format(
                    "MMMM Do, YYYY h:ss A"
                  )}
                </p>
                <div className="flex items-center">
                  <p>
                    <a
                      className="current__sports__lines__view__details"
                      href={`https://streetlines.bet/${line.AwayTeam_Desc.split(" ").join(
                        "-"
                      )}-vs-${line.HomeTeam_Desc.split(" ").join("-")}/${
                        line.Game_Num
                      }/${line.League}/details`}
                      style={{ cursor: "pointer" }}
                      target="_blank" rel="noopener noreferrer"
                    >
                      View Details <FontAwesomeIcon icon={faAngleRight} />
                    </a>
                  </p>

                  {(line.League === "NBA" || line.League === "MLB") && (
                    <p className="ml-5">
                      <a
                        className="current__sports__lines__view__details"
                        href={`https://streetlines.bet/${line.AwayTeam_Desc.split(" ").join(
                          "-"
                        )}-vs-${line.HomeTeam_Desc.split(" ").join("-")}/${
                          line.Game_Num
                        }/${line.League}/players`}
                        style={{ cursor: "pointer" }}
                        target="_blank" rel="noopener noreferrer"
                      >
                        View Player Props{" "}
                        <FontAwesomeIcon icon={faAngleRight} />
                      </a>
                    </p>
                  )}
                </div>
              </section>
            </section>
          ))}
      </section>
    );

  return (
    <section id="current__sports__lines">
      <section className="current__sports__lines__container">
        <section className="current__sports__lines__desktop__only" />
        <h2>
          ({searchQuery ? filteredLines.length : currentLines.length}){" "}
          {currentSport} Current Sports Lines
        </h2>
        <section className="current__sports__lines__toggle__container">
          <input type="checkbox" onClick={() => setHalf()} />
          <div className="current__sports__lines__slider current__sports__lines__round" />
        </section>
      </section>

      <section className="current__sports__lines__table__headings">
        {renderTableHeadings()}
      </section>
      <hr className="current__sports__lines__line" />
      {!searchQuery ? mapLines(currentLines) : mapLines(filteredLines)}
    </section>
  );
};

export default CurrentSportsLines;
