import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import MobileMenu from "../Components/Header/MobileMenu";
import styles from "../Screens/Home/HomeScreen.module.scss";
import { NavLink } from "react-router-dom";
import BetCalculator from "../Components/BetCalculator/BetCalculator";
import BaseballChew from "../Assets/images/baseball-chew.png";
import IceColdFacts from "../Assets/images/ice-cold-facts.png";
import SurivalOfTheFittest from "../Assets/images/survival-of-the-fittest.png";
import ToughTurf from "../Assets/images/tough-turf.png";
import WhatsUpHoops from "../Assets/images/whatsup-hoops.png";
import GoalTime from "../Assets/images/goal-time.png";

const Layout = ({
  children,
  hotOffThePressRef,
  modal,
  openModal,
  closeModal,
}) => {
  const [betCalculatorModal, setBetCalculatorModal] = useState(false);

  useEffect(() => {
    function onKeyup(e) {
      if (e.keyCode === 27) {
        closeModal();
        setBetCalculatorModal(false);
      }
    }

    window.addEventListener("keydown", onKeyup);
  }, []);

  useEffect(() => {
    let elem = document.querySelector("#extralarge-modal");

    if (elem) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  });

  return (
    <>
      <Header
        hotOffThePressRef={hotOffThePressRef}
        setBetCalculatorModal={setBetCalculatorModal}
      />
      <MobileMenu
        hotOffThePressRef={hotOffThePressRef}
        openSportsLines={() => openModal()}
        setBetCalculatorModal={setBetCalculatorModal}
        modal={modal}
      />
      <section className={styles.headerContainer}>
        <div className={styles.subNav}>
          <ul>
            <li>
              <NavLink to="/about">About Players Circle</NavLink>
            </li>

            <li>
              <NavLink to="/live-scores">Live Scores</NavLink>
            </li>
            <li>
              <NavLink to="/coaches-corner">Coaches Corner</NavLink>
            </li>
            <li>
              <NavLink to="/tutorials">Tutorials</NavLink>
            </li>
            <li>
              <NavLink to="/contact-us">Contact Us</NavLink>
            </li>
          </ul>
        </div>
      </section>
      <section className={styles.subMenu}>
        <NavLink
          to="/baseball-chew"
          className={({ isActive }) => (isActive ? styles.activeImg : "")}
        >
          <img
            src={BaseballChew}
            alt="Baseball inside glove"
            onClick={() =>
              hotOffThePressRef?.current?.scrollIntoView({ behavior: "smooth" })
            }
          />
        </NavLink>
        <NavLink
          to="/whats-up-hoops"
          className={({ isActive }) => (isActive ? styles.activeImg : "")}
        >
          <img
            src={WhatsUpHoops}
            alt="Basketball in gym"
            onClick={() =>
              hotOffThePressRef?.current?.scrollIntoView({ behavior: "smooth" })
            }
          />
        </NavLink>
        <NavLink
          to="/tough-turf"
          className={({ isActive }) => (isActive ? styles.activeImg : "")}
        >
          <img
            src={ToughTurf}
            alt="American Football player holding a football"
            onClick={() =>
              hotOffThePressRef?.current?.scrollIntoView({ behavior: "smooth" })
            }
          />
        </NavLink>
        <NavLink
          to="/survival-of-the-fittest"
          className={({ isActive }) => (isActive ? styles.activeImg : "")}
        >
          <img
            src={SurivalOfTheFittest}
            alt="Two boxers fighting"
            onClick={() =>
              hotOffThePressRef?.current?.scrollIntoView({ behavior: "smooth" })
            }
          />
        </NavLink>
        <NavLink
          to="/ice-cold-facts"
          className={({ isActive }) => (isActive ? styles.activeImg : "")}
        >
          <img
            src={IceColdFacts}
            alt="Hockey player on ice"
            onClick={() =>
              hotOffThePressRef?.current?.scrollIntoView({ behavior: "smooth" })
            }
          />
        </NavLink>
        <NavLink
          to="/goal-time"
          className={({ isActive }) => (isActive ? styles.activeImg : "")}
        >
          <img
            src={GoalTime}
            alt="Soccer in goal"
            onClick={() =>
              hotOffThePressRef?.current?.scrollIntoView({ behavior: "smooth" })
            }
          />
        </NavLink>
      </section>
      {betCalculatorModal && (
        <div
          id="extralarge-modal"
          tabIndex="-1"
          className="z-50 fixed inset-0 bg-gray-500 bg-opacity-50 h-full w-full"
        >
          <div className="relative p-4 w-full max-w-2xl h-full inset-0 md:top-20 mx-auto p-5 border lg:w-2/3 md:h-5/6 rounded-md">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col h-full">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center p-5 rounded-t border-b dark:border-solid dark:border-gray-600 flex-1">
                <h3 className="text-xl font-medium text-gray-900 dark:text-white uppercase">
                  Bet Calculator
                </h3>
                <button
                  type="button"
                  onClick={() => setBetCalculatorModal(false)}
                  className="cursor-pointer text-gray-400 bg-transparent border-none hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="extralarge-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6 overflow-y-auto flex-[10] md:h-[35rem]">
                <BetCalculator setBetCalculatorModal={setBetCalculatorModal} />
                <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  <span className="text-xl uppercase border-b border-solid">
                    How to use:
                  </span>
                  <ul>
                    <li className="mt-5">
                      Enter the moneyline odds of each game in your parlay.
                    </li>
                    <li className="mt-5">
                      If you choose a favorite, you must enter the moneyline
                      with a minus (-) sign at the beginning (e.g. -120).
                    </li>
                    <li className="mt-5">
                      * Note: Most standard pointspread and total wagers have
                      -110 moneyline odds.
                    </li>
                    <li className="mt-5">
                      If you choose an underdog, simply enter the moneyline
                      odds; no plus sign (+) is necessary (e.g. 120).
                    </li>
                    <li className="mt-5">
                      Enter the amount of your wager in the box next to "Bet
                      Amount ($)". The amount of a winning parlay wager will
                      appear in the box next to "Payout Total".{" "}
                    </li>
                    <li className="mt-5">
                      Enter up to 11 additional (12 total) lines.{" "}
                    </li>
                    <li className="mt-5">
                      * Note: The "Payout Total" includes the amount wagered.
                    </li>
                    <li className="mt-5">
                      Click calculate to view payout information.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex items-center p-6 space-x-2 rounded-b border-t border-solid border-gray-200 dark:border-gray-600 flex-1">
                <button
                  data-modal-toggle="extralarge-modal"
                  type="button"
                  className="cursor-pointer text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => setBetCalculatorModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {children}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  footerBg: PropTypes.string,
};

export default Layout;
