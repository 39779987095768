import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import {animateScroll} from "react-scroll";
import { MdExpandLess } from "react-icons/md";
import styles from "./Footer.module.scss";
import Logo from "../../Assets/images/logo.png";

const Footer = () => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <>
      <section className={styles.footerContainer}>
        <img className={styles.logo} src={Logo} alt="" />
        <ul>
          <a
            href="https://twitter.com/plnetworksllc"
            target="_blank"
            rel="noreferrer"
          >
            <li>Follow us on Twitter</li>
          </a>
          <Link to="/terms-of-service">
            <li>Terms Of Service</li>
          </Link>
          <Link to="privacy-policy">
            <li>Privacy Policy</li>
          </Link>
        </ul>
      </section>
      <section className={styles.tradeMark}>
        <span>
          PLAYERS CIRCLE IS A PRODUCT OF PLNETWORK SRL. ALL RIGHTS RESERVED{" "}
          {new Date().getFullYear()}.
        </span>
      </section>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <MdExpandLess />
      </button>
    </>
  );
};

export default Footer;
