import React, { useEffect, useState } from "react";
import StreetLinesComp from "./StreetLines";
import styles from "../../Screens/Home/HomeScreen.module.scss";
import Plus from "../../Assets/images/plus.png";
import AutoScroll from "../AutoScroll/AutoScroll";
import Logo from "../../Assets/images/streetlines.png";
// import dummyData from "./dummyData.json";

const StreetLines = ({ autoScroll, openModal, closeModal, modal }) => {
  let options = [];
  const [currentSport, setCurrentSport] = useState("");
  const [loading, setLoading] = useState(true);

  const [lines, setLines] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [half, setHalf] = useState(true);

  const [backgroundColor, setBackgroundColor] = React.useState("inherit");
  const [color, setColor] = React.useState("inherit");
  const updateTimer = React.useRef(null);

  // const [modal, setModal] = useState(false);

  //
  // const openModal = () => setModal(true);
  // const closeModal = () => setModal(false);

  useEffect(() => {
    function onKeyup(e) {
      if (e.keyCode === 27) {
        closeModal();
        // setBetCalculatorModal(false);
      }
    }

    window.addEventListener("keydown", onKeyup);
  }, []);

  useEffect(() => {
    setUpdate();
  }, [lines]);

  function setUpdate() {
    setBackgroundColor("green");
    setColor("red");
    updateTimer.current = setTimeout(() => {
      setBackgroundColor("inherit");
      setColor("inherit");
      updateTimer.current = null;
    }, 1000);
  }

  const getAllOptions = () =>
    lines.map((l) => {
      if (!options.includes(l.League)) {
        options.push(l.League);
        setSelectOptions(options.sort());
        if (!currentSport) {
          setCurrentSport(options[0]);
        }
        return true;
      } else {
        return false;
      }
    });

  useEffect(() => {
    getAllOptions();
    // eslint-disable-next-line
  }, [lines]);

  useEffect(() => {
    setTimeout(async () => {
      const res = await fetch("https://poweroffice.plnserver.com/linesdata");
      const jsonData = await res.json();
      setLines(
        jsonData.sort((a, b) =>
          a.Line_Desc > b.Line_Desc ? 1 : b.Line_Desc > a.Line_Desc ? -1 : 0
        )
      );
      setLoading(false);
      // console.log("refetched");
    }, 10000);
    return () => {};
  }, [lines]);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`https://poweroffice.plnserver.com/linesdata`);
        const jsonData = await res.json();
        setLines(
          jsonData.sort((a, b) =>
            a.Line_Desc > b.Line_Desc ? 1 : b.Line_Desc > a.Line_Desc ? -1 : 0
          )
        );
        setLoading(false);
      } catch (error) {
        console.error(error);
        // setLines(dummyData);
        setLines([]);
        setLoading(false);
      }
    })();
  }, []);

  // const getLineDetails = (currentLines, match) => currentLines.filter(c => c.Game_Num === match.params.gameId);

  return (
    <>
      {autoScroll && <AutoScroll lines={lines} />}
      {modal && (
        <div
          id="extralarge-modal"
          tabIndex="-1"
          className="z-50 fixed inset-0 bg-gray-500 bg-opacity-50 h-full w-full"
        >
          <div className="relative p-4 w-full h-full inset-0 md:top-20 mx-auto p-5 border lg:w-2/3 md:h-5/6 rounded-md">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col h-full">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center p-5 rounded-t border-b dark:border-solid dark:border-gray-600 flex-1">
                <img
                  className="w-52 md:w-96"
                  src={Logo}
                  alt="StreetLines Logo"
                />
                {/* <h3 className="text-xl font-medium text-gray-900 dark:text-white uppercase">
                  STREET LINES
                </h3> */}
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="cursor-pointer text-gray-400 bg-transparent border-none hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="extralarge-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6 overflow-y-auto flex-[10] md:h-[35rem]">
                {/* <div id={styles.full_Story_modal_body} className="text-base leading-relaxed text-gray-500 dark:text-gray-400" dangerouslySetInnerHTML={{ __html: storyText }} />       */}
                <StreetLinesComp
                  searchQuery={searchQuery}
                  currentSport={currentSport}
                  half={half}
                  lines={lines}
                  loading={loading}
                  selectOptions={selectOptions}
                  setHalf={() => setHalf(!half)}
                  setCurrentSport={setCurrentSport}
                  setSearchQuery={setSearchQuery}
                  options={options}
                  setLines={setLines}
                  backgroundColor={backgroundColor}
                  color={color}
                  closeModal={closeModal}
                />
              </div>
              {/* <!-- Modal footer --> */}
              <div className="flex items-center p-6 space-x-2 rounded-b border-t border-solid border-gray-200 dark:border-gray-600 flex-1">
                <button
                  data-modal-toggle="extralarge-modal"
                  type="button"
                  className="cursor-pointer text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => closeModal()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {modal && <div id="myModal" className="modal">
        <div className="modal-content">
          <span className="close" onClick={() => closeModal()}>&times;</span>
          <img src={Logo} alt="StreetLines Logo"/>
          <StreetLinesComp searchQuery={searchQuery} currentSport={currentSport} half={half}
                           lines={lines} loading={loading} selectOptions={selectOptions}
                           setHalf={() => setHalf(!half)} setCurrentSport={setCurrentSport}
                           setSearchQuery={setSearchQuery} options={options} setLines={setLines}
                           backgroundColor={backgroundColor}
                           color={color} closeModal={closeModal}/>
        </div>
      </div>} */}
      <section style={{ position: "fixed", right: "2%", top: "50%" }}>
        <section className={styles.sportsLines} onClick={() => openModal()}>
          <h2>Sports Lines</h2>
          <img src={Plus} alt="" />
        </section>
      </section>
    </>
  );
};

export default StreetLines;
