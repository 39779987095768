import React, { useState, useRef, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HomeScreen from "./Screens/Home/HomeScreen";
import NotFound from "./Screens/NotFound/NotFound";
import VideosScreen from "./Screens/Videos/VideosScreen";
import Layout from "./Layouts";
import AboutScreen from "./Screens/About/AboutScreen";
import CoachesCorner from "./Screens/CoachesCorner/CoachesCornerScreen";
import Contact from "./Screens/Contact/Contact";
import useGoogleAnalytics from "./useGoogleAnalytics";
import Tos from "./Screens/Tos/Tos";
import Privacy from "./Screens/Privacy/Privacy";
import LiveScores from "./Screens/LiveScores/LiveScores";
import "./css/style.scss";

import AOS from "aos";
import Sticky from "sticky-js";

const NavRoutes = ({
  hotOffThePressRef,
  closeModal,
  openModal,
  modal,
  openStoryModal,
  closeStoryModal,
  storyModal,
  storyText,
  setStoryText,
  setStoryHero,
  storyHero,
}) => {
  useGoogleAnalytics();
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <HomeScreen
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
              hotOffThePressRef={hotOffThePressRef}
              openStoryModal={openStoryModal}
              closeStoryModal={closeStoryModal}
              storyModal={storyModal}
              setStoryText={setStoryText}
              storyText={storyText}
              setStoryHero={setStoryHero}
              storyHero={storyHero}
            />
          }
        />
        <Route
          path="/baseball-chew"
          element={
            <HomeScreen
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
              hotOffThePressRef={hotOffThePressRef}
              openStoryModal={openStoryModal}
              closeStoryModal={closeStoryModal}
              storyModal={storyModal}
              setStoryText={setStoryText}
              storyText={storyText}
              setStoryHero={setStoryHero}
              storyHero={storyHero}
            />
          }
        />
        <Route
          path="/whats-up-hoops"
          element={
            <HomeScreen
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
              hotOffThePressRef={hotOffThePressRef}
              openStoryModal={openStoryModal}
              closeStoryModal={closeStoryModal}
              storyModal={storyModal}
              setStoryText={setStoryText}
              storyText={storyText}
              setStoryHero={setStoryHero}
              storyHero={storyHero}
            />
          }
        />
        <Route
          path="/tough-turf"
          element={
            <HomeScreen
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
              hotOffThePressRef={hotOffThePressRef}
              openStoryModal={openStoryModal}
              closeStoryModal={closeStoryModal}
              storyModal={storyModal}
              setStoryText={setStoryText}
              storyText={storyText}
              setStoryHero={setStoryHero}
              storyHero={storyHero}
            />
          }
        />
        <Route
          path="/survival-of-the-fittest"
          element={
            <HomeScreen
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
              hotOffThePressRef={hotOffThePressRef}
              openStoryModal={openStoryModal}
              closeStoryModal={closeStoryModal}
              storyModal={storyModal}
              setStoryText={setStoryText}
              storyText={storyText}
              setStoryHero={setStoryHero}
              storyHero={storyHero}
            />
          }
        />
        <Route
          path="/ice-cold-facts"
          element={
            <HomeScreen
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
              hotOffThePressRef={hotOffThePressRef}
              openStoryModal={openStoryModal}
              closeStoryModal={closeStoryModal}
              storyModal={storyModal}
              setStoryText={setStoryText}
              storyText={storyText}
              setStoryHero={setStoryHero}
              storyHero={storyHero}
            />
          }
        />
        <Route
          path="/goal-time"
          element={
            <HomeScreen
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
              hotOffThePressRef={hotOffThePressRef}
              openStoryModal={openStoryModal}
              closeStoryModal={closeStoryModal}
              storyModal={storyModal}
              setStoryText={setStoryText}
              storyText={storyText}
              setStoryHero={setStoryHero}
              storyHero={storyHero}
            />
          }
        />
        <Route
          path="/about"
          element={
            <AboutScreen
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
            />
          }
        />
        <Route
          exact
          path="/live-scores"
          element={
            <LiveScores
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
            />
          }
        />
        {/*<Route path="/:awayTeam-vs-:homeTeam/:gameId"*/}
        {/*       element={h}) => <LineDetails match={match} currentLines={getLineDetails(match)}/>}/>*/}
        {/*<Route path="/live-scores/:teams/:id" component={LiveScoresDetails}/>*/}
        <Route
          path="/coaches-corner"
          element={
            <CoachesCorner
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
            />
          }
        />
        <Route
          path="/contact-us"
          element={
            <Contact
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
            />
          }
        />
        <Route
          path="/tutorials"
          element={
            <VideosScreen
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
            />
          }
        />
        <Route
          path="/terms-of-service"
          element={
            <Tos modal={modal} openModal={openModal} closeModal={closeModal} />
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Privacy
              modal={modal}
              openModal={openModal}
              closeModal={closeModal}
            />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

const App = () => {
  const aboutUsRef = useRef(null);
  const hotOffThePressRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [storyModal, setStoryModal] = useState(false);
  const [storyText, setStoryText] = useState(null);
  const [storyHero, setStoryHero] = useState(null);
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  const openStoryModal = () => setStoryModal(true);
  const closeStoryModal = () => {
    setStoryModal(false);
    setStoryText(null);
    setStoryHero(null);
  };

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky("[data-sticky]");
  });

  useEffect(() => {
    console.log(location.pathname);
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <Layout
      aboutUsRef={aboutUsRef}
      hotOffThePressRef={hotOffThePressRef}
      openModal={openModal}
      closeModal={closeModal}
      modal={modal}
    >
      <NavRoutes
        hotOffThePressRef={hotOffThePressRef}
        openModal={openModal}
        closeModal={closeModal}
        modal={modal}
        storyModal={storyModal}
        openStoryModal={openStoryModal}
        closeStoryModal={closeStoryModal}
        setStoryText={setStoryText}
        storyText={storyText}
        setStoryHero={setStoryHero}
        storyHero={storyHero}
      />
    </Layout>
  );
};

export default App;
