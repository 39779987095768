import React from "react";
import MetaTags from "react-meta-tags";
import styles from "./CoachesCornerScreen.module.scss";
import Banner from "../../Assets/images/promotional-banner-footer.png";
import footerStyles from "../Home/HomeScreen.module.scss";
import Coach from "../../Assets/images/coach.png";
import { Link } from "react-router-dom";
import BetCalculator from "../../Components/BetCalculator/BetCalculator";
import StreetLines from "../../Components/StreetLines";

const CoachesCorner = ({ closeModal, openModal, modal }) => {
  return (
    <section className="border-b border-solid border-gray-300 grow">
      <header className="w-full bg-[#091368] text-white mx-auto mb-20 flex items-center justify-center">
        <img className="relative hidden md:flex" src={Coach} alt="Coach" />
        {/* Title */}
        <h1 className="h1 text-4xl lg:text-7xl text-boldest text-center m-4 uppercase">
          Coaches Corner
        </h1>
      </header>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          <div className="max-w-3xl mx-auto lg:max-w-none">
            <article>
              {/* Article header */}
              {/* <header className="max-w-3xl mx-auto mb-20"> */}
              {/* Title */}
              {/* <h1 className="h1 text-7xl text-boldest text-center mb-4 uppercase"> */}
              {/* Coaches Corner */}
              {/* </h1> */}
              {/* </header> */}

              {/* Article content */}
              <div className="lg:flex lg:justify-between" data-sticky-container>
                {/* Sidebar */}
                <aside className="relative hidden lg:block w-64 mr-20 shrink-0">
                  <div
                    data-sticky
                    data-margin-top="100"
                    data-sticky-for="768"
                    data-sticky-wrap
                  >
                    <h4 className="text-lg font-bold leading-snug tracking-tight mb-4">
                      Table of contents
                    </h4>
                    <ul className="font-medium -my-1">
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#definitions"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Definitions</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#favorite"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Favorites</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#differentMlFormats"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Moneyline Formats</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#fractionalOdds"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Fractional Odds</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#americanOdds"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>American Odds</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#decimalOdds"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Decimal Odds</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#juice"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>The Juice</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#buyAndSell"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Buy & Sell Points</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#teaser"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Teaser Betting</span>
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          className="flex items-center hover:underline"
                          href="#ifBetting"
                        >
                          <svg
                            className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>IF Betting</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </aside>

                {/* Main content */}
                <div>
                  {/* Article meta */}
                  <div className="flex items-center mb-6">
                    <div className="flex shrink-0 mr-3">
                      <a className="relative" href="#0">
                        <span
                          className="absolute inset-0 -m-px"
                          aria-hidden="true"
                        >
                          <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                        </span>
                        <img
                          className="relative rounded-full"
                          src={
                            "https://pbs.twimg.com/profile_images/1356027761811451906/eoCGqeKZ_400x400.jpg"
                          }
                          width="32"
                          height="32"
                          alt="Author 04"
                        />
                      </a>
                    </div>
                    <div>
                      <span className="text-gray-600">Property of </span>
                      <a
                        href="https://twitter.com/plnetworksllc"
                        target="_blank"
                        rel="noreferrer"
                        className="font-medium hover:underline"
                      >
                        PLNETWORKS SRL
                      </a>
                      <span className="text-gray-600">
                        {" "}
                        · Last Updated July 17, 2020
                      </span>
                    </div>
                  </div>
                  <hr className="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                  {/* Article body */}
                  <div className="text-lg text-gray-600">
                    <p
                      id="definitions"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      What is the Definition of each type of BET?
                    </p>
                    <p className="underline">
                      Money line (ML, JML, H1ML, H2ML, T1ML, T2ML, T3ML, Q1ML,
                      Q2ML, Q3ML, Q4ML):
                    </p>
                    <p className="mb-8">
                      Money line is a simple bet. You are simply betting one
                      team to beat the other at the end of the selected match or
                      period. It does not have anything to do with spread or
                      points.
                    </p>
                    <p className="underline">
                      Spread betting (J, H1, H2, R, SR, T1, T2, T3, Q1, Q2, Q3,
                      Q4):
                    </p>

                    <ul className="list-disc list-inside mb-8">
                      <li className="mb-1">
                        A form of betting in which the bettor wins or loses
                        money according to the margin by which the value of a
                        particular outcome varies from the spread of expected
                        values quoted by the bookmaker.
                      </li>
                      <li className="mb-1">
                        A point spread is a number oddsmakers use as a median
                        between two teams or sides to generate betting interest
                        on both teams.
                      </li>
                      <li className="mb-1">
                        A point spread in sports is a figure set by oddsmakers
                        to provide an advantage or disadvantage based on the
                        margin of victory or defeat for a given team.
                      </li>
                    </ul>

                    <p className="underline">Totals (-J, +J):</p>

                    <p className="mb-8">
                      An over/under (O/U) bet is a wager in which a sportsbook
                      will predict a number for a statistic in a given game
                      (usually the combined score of the two teams), and bettors
                      wager that the actual number in the game will be either
                      higher or lower than that number.
                    </p>

                    <p className="underline">Team Solo (ES-, ES+):</p>
                    <p className="mb-8">
                      If you bet Team Solo over/under, you are betting on the
                      number of total points at the end of the entire game. Only
                      the chosen team points/score including extra-Innings or
                      Extra Time will be accounted for (unless otherwise
                      indicated).
                    </p>

                    <p className="underline">Bases / Player Props (B):</p>
                    <p className="mb-8">
                      This bet is two players who face off in an event. The
                      player who advances the most bases by way of hits is
                      considered the winner. A double is considered two bases
                      while a homerun is counted as four bases. If in
                      Basketball, Football, Hockey, Soccer the player who
                      outscores the other is the winner.
                    </p>

                    <p className="underline">First Run (F):</p>
                    <p className="mb-8">
                      This bet is based on the team who scores first, however
                      the opposing team does not necessarily need to take turns
                      for this bet to be valid.
                    </p>

                    <p className="underline">Last Run (L):</p>
                    <p className="mb-8">
                      This bet is based on the team that scores last; however,
                      the opposing team does not necessarily need to take turns
                      for this bet to be valid.
                    </p>

                    <p className="underline">First Half (H1):</p>
                    <p className="mb-8">
                      You are betting that the selected team must win by more
                      than the points described in the bet at the end of the
                      first half of the match. This type of betting includes a
                      Spread which must be covered by the Favorite for the
                      Favorite to be considered a winner otherwise the Underdog
                      will win by way of the Spread.
                    </p>

                    <p className="underline">First Total Half (H1-, H1+):</p>
                    <p className="mb-8">
                      You're betting on the combined total number of runs or
                      points at the end of the first half of the match.
                    </p>

                    <p className="underline">
                      First Half to Money Line (H1ML):
                    </p>
                    <p className="mb-8">
                      A simple and direct bet. You are betting on one team to
                      beat the other at the end of the first half of the match.
                    </p>

                    <p className="underline">Second Half (H2):</p>
                    <p className="mb-8">
                      You are betting that the selected team will win by more
                      than the points spread indicated on the bet slip. The bet
                      includes any extra-innings or extra-time unless indicated
                      in the selected bet type.
                    </p>

                    <p className="underline">Second Half Totals (H2-, H2+):</p>
                    <p className="mb-8">
                      You are betting on the combined total number points at the
                      end of the second half of the game extra-innings or
                      extra-time is included unless indicated in the selected
                      bet type.
                    </p>

                    <p className="underline">
                      Second Half to Money Line (H2ML):
                    </p>
                    <p className="mb-8">
                      A simple and direct bet. You are betting on one team to
                      beat the other at the end of the second half of the match
                      including any extra-innings or overtime.
                    </p>

                    <p className="underline">Strikeouts (K-, K+):</p>
                    <p className="mb-8">
                      When selecting over/under strikeouts the chosen pitcher
                      must throw at the beginning of the game and needs to
                      strike out the total amount of players to win the over
                      strikeouts bet. Otherwise, the bets on under strikeouts
                      wins.
                    </p>

                    <p className="underline">YES (Y):</p>
                    <p className="mb-8">
                      When you bet YES, you are betting that the team chosen or
                      opposing team will score at least 1 run in the first
                      inning.
                    </p>

                    <p className="underline">NO (N):</p>
                    <p className="mb-8">
                      When you bet NO, you are betting that the team chosen or
                      opposing team will NOT score at least 1 run in the first
                      inning.
                    </p>
                    <p className="underline">First Quarter (Q1):</p>
                    <p className="mb-8">
                      You are betting that the chosen team will beat the
                      opposing team at the end of the first quarter including
                      points or spread.
                    </p>
                    <p className="underline">Second Quarter (Q2):</p>
                    <p className="mb-8">
                      You are betting that the chosen team will beat the
                      opposing team at the end of the second quarter including
                      points or spread.
                    </p>
                    <p className="underline">Third Quarter (Q3):</p>
                    <p className="mb-8">
                      You are betting that the chosen team will beat the
                      opposing team at the end of the third quarter including
                      points or spread.
                    </p>
                    <p className="underline">Fourth Quarter (Q4):</p>
                    <p className="mb-8">
                      You are betting that the chosen team will beat the
                      opposing team at the end of the Fourth quarter including
                      points or spread.
                    </p>
                    <p className="underline">
                      First Quarter Totals (Q1-, Q1+):
                    </p>
                    <p className="mb-8">
                      You are betting that the total points will be more or less
                      than the line indicated at the end of the first quarter.
                    </p>
                    <p className="underline">
                      Second Quarter Totals (Q2-, Q2+):
                    </p>
                    <p className="mb-8">
                      You are betting that the total points will be more or less
                      than the line indicated at the end of the second quarter.
                    </p>
                    <p className="underline">
                      Third Quarter Totals (Q3-, Q3+):
                    </p>
                    <p className="mb-8">
                      You are betting that the total points will be more or less
                      than the line indicated at the end of the third quarter.
                    </p>
                    <p className="underline">
                      Fourth Quarter Totals (Q4-, Q4+):
                    </p>
                    <p className="mb-8">
                      You are betting that the total points will be more or less
                      than the line indicated at the end of the last quarter.
                    </p>
                    <p className="underline">Run Line(R):</p>
                    <p className="mb-8">
                      The line for baseball run line is 1.5. With a 1.5 run line
                      you will be able to place a wager on the favored team to
                      win by 2 runs or more, or the underdog team to lose by 1
                      run or to win the game. In baseball and hockey, point
                      spreads are called “run line” or “puck line” and are set
                      at -1.5 or +1.5 with the -1.5 given to the favorite and
                      the +1.5 given to the underdog.
                    </p>
                    <p className="underline">Super Run Line (SR):</p>
                    <p className="mb-8">
                      The line for baseball super run line is 2.5. With a 2.5
                      super run line you will be able to place a wager on the
                      favored team to win by 3 runs or more, or the underdog
                      team to lose by 2 runs or to win the game.
                    </p>
                    <p className="underline">Alternative Run Line (AR):</p>
                    <p className="mb-8">
                      This bet reverses the run line and allows you to bet on
                      the Favorite team as an underdog or vice versa.{" "}
                    </p>
                    <p className="underline">First Third (T1):</p>
                    <p className="mb-8">
                      You are betting that the chosen team will beat the
                      opposing team at the end of 1/3 of the total game
                      including any spread listed for that period.
                    </p>
                    <p className="underline">Second Third (T2):</p>
                    <p className="mb-8">
                      You are betting that the chosen team will beat the
                      opposing team at the end of 2/3 of the total game
                      including any spread listed for that period.{" "}
                    </p>
                    <p className="underline">Last Third (T3):</p>
                    <p className="mb-8">
                      You are betting that the chosen team will beat the
                      opposing team at the end of the last third of the total
                      game including any spread listed for that period.
                    </p>
                    <p className="underline">
                      First Third Over/Under (T1-, T1+):
                    </p>
                    <p className="mb-8">
                      You are betting that the total points or runs will be more
                      or less than the line indicated at the end of 1/3 of total
                      game.{" "}
                    </p>
                    <p className="underline">
                      Second Third Over/Under (T2-, T2+):
                    </p>
                    <p className="mb-8">
                      You are betting that the total points or runs will be more
                      or less than the line indicated at the end of 2/3 of total
                      game.
                    </p>
                    <p className="underline">
                      Last Third Over/Under (T3-, T3+):
                    </p>
                    <p className="mb-8">
                      You are betting that the total points or runs will be more
                      or less than the line indicated at the end of the last
                      third of the game.
                    </p>
                    <p className="underline">Teaser (T):</p>
                    <p className="mb-8">
                      A teaser is a special parlay in which the bettor gets a
                      favorable point adjustment. Teasers are available in
                      football and basketball and may be wagered on Full Game or
                      Total points.{" "}
                    </p>
                    <p className="underline">IF (IF):</p>
                    <p className="mb-8">
                      An IF bet is a conditional bet which permits the bettor to
                      wager on the condition that a primary bet will win. This
                      grants the bettor the ability to make a wager on a
                      conditional bet using the money which will be won on the
                      Primary bet. If two events are starting before the
                      completion of the primary the bettor may choose to create
                      a conditional bet (IF) and wager with the money he/she
                      will receive after the primary bet has won. If the Primary
                      bet does not win the IF BET is null and has “No Action”
                      since there isn’t any money collected to pay for the IF
                      bet in advance.{" "}
                    </p>
                    <h3
                      id="favorite"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      What is a Favorite vs Underdog?
                    </h3>
                    <p className="mb-8">
                      A Money line bet usually has two opponents or teams. One
                      opponent will be considered a Favorite and the other an
                      Underdog. The Favorite is the Team/Opponent whose wager
                      must be greater than what you will receive per each $1.
                      The Underdog is the Team/Opponent whose wager is less than
                      what you will receive per each $1. There is an exception
                      which is where both Team/Opponent are considered equal and
                      you must then wager $1 + the Juice to win $1. The best way
                      to understand is via an example below.
                    </p>
                    <p className="underline">Example</p>

                    <table className="my-8">
                      <tbody>
                        <tr>
                          <th className="">Team:</th>
                          <th className="px-2">ML</th>
                          <th className="px-2">Spread</th>
                          <th className="px-2">Total Points</th>
                        </tr>
                        <tr>
                          <td className="px-2">Houston Rockets</td>
                          <td className="">-110</td>
                          <td className="px-2">-1 (-110)</td>
                          <td className="px-2">223 O -110</td>
                        </tr>
                        <tr>
                          <td className="px-2">New Orleans Pelicans</td>
                          <td className="">-110</td>
                          <td className="px-2">1 (110)</td>
                          <td className="px-2">223 U -110</td>
                        </tr>
                      </tbody>
                    </table>

                    <ul className="list-disc list-inside mb-8">
                      <li className="mb-1">
                        <strong className="font-medium text-gray-900">
                          Rockets -110
                        </strong>
                        : The Bettor has to layout $110 to Win $100
                      </li>
                      <li className="mb-1">
                        <strong className="font-medium text-gray-900">
                          Pelicans -110
                        </strong>
                        : The Bettor has to layout $110 to win $100
                      </li>
                    </ul>
                    <p className="mb-8">
                      In the example above the Rockets and Pelicans are
                      considered equal as far as Moneyline bets are considered (
                      -110 is known as Pick'em), however if you look at the
                      Spread you will notice that the Pelicans are the Favorite
                      because you need to layout -1 point to win which means
                      Pelicans must cover the -1 if you bet Spread whereas if
                      you bet Moneyline it only must win outright.
                    </p>

                    {/* next item in list */}
                    <h3
                      id="differentMlFormats"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Different Moneyline Formats
                    </h3>
                    <p className="mb-8">
                      There are three formats which a Moneyline wager can be
                      listed as.
                    </p>

                    <table>
                      <tbody>
                        <tr>
                          <th className="">Moneyline Formats:</th>
                          <th className="px-2">American</th>
                          <th className="px-2">Decimal</th>
                          <th className="px-2">Fractional</th>
                        </tr>
                        <tr>
                          <td className=""></td>
                          <td className="px-2">Los Angeles Lakers -130</td>
                          <td className="px-2">0.769230</td>
                          <td className="px-2">10/13</td>
                        </tr>
                        <tr>
                          <td className=""></td>
                          <td className="px-2">Boston Celtics 110</td>
                          <td className="px-2">1.1</td>
                          <td className="px-2">11/10</td>
                        </tr>
                      </tbody>
                    </table>

                    <p className="my-8 italic">
                      <strong className="font-medium text-gray-900">
                        Fractional odds are the ratio of the amount (profit) won
                        to the stake; Decimal odds represent the amount one wins
                        for every $1 wagered; and American odds, depending on
                        the negative or positive sign, either indicate the
                        amount one needs to wager to win $100 or the amount one
                        would win for every $100 staked.
                      </strong>
                    </p>

                    <h3
                      id="fractionalOdds"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      How do you calculate Fractional odds?
                    </h3>
                    <p className="mb-8">
                      To calculate winnings on fractional odds, divide numerator
                      by the denominator then multiply by the wager. So, a $130
                      wager at 10/13 odds is (10/13) * 130, which equals $100. A
                      $100 bet at 11/10 odds is (11/10) *100, which is $110.
                    </p>

                    <h3
                      id="americanOdds"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      How do you calculate American odds?
                    </h3>
                    <p className="mb-8">
                      To calculate winnings on American odds, If the odds are
                      positive divide the odds by the wager amount then multiply
                      by wager. A $100 bet at 110 is (110/100)*100=$110. If the
                      odds are Negative, you divide wager amount by odds and
                      multiply wager amount by negative 1, then multiply both
                      results. So, a $100 bet at -130 is
                      (100/-130)*(100*-1)=$76.92.
                    </p>

                    <h3
                      id="decimalOdds"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      How do you calculate Decimal odds?
                    </h3>
                    <p className="mb-8">
                      To calculate winnings on Decimal odds, multiply your wager
                      by the decimal. So, a $100 bet at 1.1 odds is (100*1.1)
                      which equals $110. A $100 bet at .769230 is (100*.769230)
                      which equals $76.92.
                    </p>

                    <p className="mb-8 italic">
                      Note: The formulas only calculate your winnings you will
                      receive the initial wager with the winnings.
                    </p>

                    <h3
                      id="juice"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      What is the Juice?
                    </h3>
                    <p className="mb-8">
                      The Juice is the amount of money the Bookmaker stands to
                      make between each team. The goal of a bookmaker is to
                      always land with the same amount of Bet on both sides. If
                      they achieve this, they will be a winner no matter what
                      the outcome is. Los Angeles Lakers are -130 so for BETTOR
                      A to make $100 he must layout $130. Boston Celtics is at
                      110 if BETTOR B bets $100 he will win $110. The Juice is
                      $20 which the bookmaker stands to make. If Los Angeles
                      Lakers lose however the bookmaker will pay the $110 with
                      the $130, he received from BETTOR A. If Boston Celtics
                      wins the Bookmaker will pay the $100 with the wager BETTOR
                      B made. However, bookmakers are smarter than that so
                      rather than gambling whether Lakers wins they hedge their
                      bets by moving lines until they are guaranteed a favorable
                      outcome.
                    </p>

                    <h3
                      id="buyAndSell"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Buying and Selling Points
                    </h3>

                    <p className="underline">What Does Buying points mean?</p>
                    <p className="mb-8">
                      When you decide to Buy points, you are assuming your team
                      will cover the spread minus the number of points you Buy.
                      If you Buy 1 Points on Los Angeles Lakers where the spread
                      is -2 (see Diagram #1) that means Lakers must Win the game
                      by More than 1 points for your wager to win. Whereas
                      normally if they win by 2 you would only push. The more
                      points you buy the more money you need to layout to win
                      $100.
                    </p>

                    <p className="underline">What does Selling points mean?</p>
                    <p className="mb-8">
                      When you decide to Sell points, you are assuming your team
                      will cover the spread plus the points you Sell. So, if you
                      take the Lakers example and sell 1 point that means Lakers
                      must win the game by more than 3 points for your wager to
                      win. The more points you sell the higher the return on a
                      $100 wager.
                    </p>

                    <h3
                      id="teaser"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      Teaser Betting
                    </h3>

                    <p className="underline">What is a Teaser Bet?</p>
                    <p className="mb-8">
                      A Teaser is a parlay bet that uses modified point spreads
                      to the Bettor’s favor. Football and Basketball is where
                      you can play such bets. The normal points you receive are
                      six in football and 4 in basketball there are some books
                      that permit the 6.5 and 4.5 also more rarely 7 and 5
                      however the more points that are in your favor the less
                      payout you will receive on your bets. Quite frankly a
                      Teaser with the 6 and 4 rule means that a team who is
                      favorite can become an underdog in the sense of the
                      teaser. For example, the Los Angeles Lakers giving -2 if
                      you tease this game you will receive Los Angeles Lakers at
                      +2 which instead of having to win the game by two, they
                      can outright lose by 1 and your Teaser is still a winner.
                      Teasers are an immensely powerful tool if you know when to
                      use it. The rules for a Push on a Teaser are quite simple,
                      if you have a 5-team parlay on a Teaser and 1 Team is a
                      push then the Teaser pushes down to the next possible
                      Parlay which in this case it will be 4 team parlay.
                      However, if you have a 2 team Teaser, and one is a winner
                      and the other a Push this ticket is a Draw/Tie because
                      Teasers must have two or more teams for them to win. You
                      will receive your initial wager amount in return.
                    </p>

                    <h3
                      id="ifBetting"
                      className="mb-8 text-md md:text-2xl"
                      style={{ scrollMarginTop: "15px" }}
                    >
                      IF (Conditional Bets)
                    </h3>

                    <p className="underline">What is an IF Bet?</p>
                    <p className="mb-8">
                      An IF bet is a conditional bet. Basically, you are
                      wagering that the Primary bet will win and with the
                      winnings you will pay for the additional (conditional)
                      bet. Let us take a closer look at an example. I like the
                      Los Angeles Lakers to cover the -2 however I also like the
                      New Orleans Pelicans +1 my problem is that both events
                      start at the same time. With an IF bet I can place both
                      bets while only paying for 1. When I select IF I will
                      select my most favorable team on top (primary bet)
                      followed by another wager on the bottom (secondary bet) I
                      can go ahead and wager $110 on Lakers -2 IF New Orleans
                      Pelican +1 with another $110. The math works out in the
                      following format:
                    </p>

                    <table>
                      <tbody>
                        <tr>
                          <td>IF:</td>
                          <td className="px-2">Los Angeles Lakers -2</td>
                          <td className="px-2">-110</td>
                          <td className="px-2">Wager $110</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="px-2">New Orleans Pelican +1</td>
                          <td className="px-2">-110</td>
                          <td className="px-2">Wager $110</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="px-2"></td>
                          <td className="px-2">Total</td>
                          <td className="px-2">Wager $110</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="px-2"></td>
                          <td className="px-2">Total</td>
                          <td className="px-2">Win amount $200</td>
                        </tr>
                      </tbody>
                    </table>

                    <p className="mb-8">
                      If both teams win, I will have successfully made two bets
                      valued at $220 while only paying $110. The potential gains
                      are $200 if both events win. Conditional betting can be
                      used to get a leg up on the amount of cash you have at
                      your disposition. Note: IF the Top (primary) wager does
                      not win then the outcome of the IF (secondary) Wager is
                      void. The reasoning is that since you never paid for the
                      IF (Secondary) Wager it only will be valid once the
                      Primary wager wins.
                    </p>

                    {/* end of content */}
                    <div>
                      <hr className="w-full h-px pt-px mt-8 bg-gray-200 border-0" />
                      <div className="mt-8">
                        Interested in more tips like this?{" "}
                        <Link
                          to="/contact-us"
                          className="text-gray-900 underline"
                        >
                          Reach out
                        </Link>{" "}
                        to let us know.
                      </div>
                      <div className="mt-6">
                        <Link
                          to="/"
                          className="inline-flex items-center text-base text-blue-600 font-medium hover:underline"
                        >
                          <svg
                            className="w-3 h-3 fill-current text-blue-400 shrink-0 mr-2"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M.293 5.282L5 .5l1.414 1.436-3 3.048H12v2.032H3.414l3 3.048L5 11.5.293 6.718a1.027 1.027 0 010-1.436z" />
                          </svg>
                          <span>Back to the Home</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Article footer */}
            </article>
          </div>
        </div>
      </div>
      <StreetLines
        modal={modal}
        closeModal={closeModal}
        openModal={openModal}
      />
    </section>
  );
};

export default CoachesCorner;
