import React from "react";
import MetaTags from "react-meta-tags";
import Banner from "../../Assets/images/promotional-banner-footer.png";
import Cheering from "../../Assets/images/cheering.jpeg";
import StreetLines from "../../Components/StreetLines";
import {
  FaChessKing,
  FaRegHandshake,
  FaToolbox,
  FaTools,
} from "react-icons/fa";
const AboutScreen = ({ modal, closeModal, openModal }) => {
  return (
    <>
      <MetaTags>
        <title>Players Circle - About Us</title>
        <meta name="description" content="About Us" />
      </MetaTags>
      <section className="w-full bg-gray-800">
        <div class="container mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
          <p class="font-normal text-sm leading-3 text-indigo-700 dark:text-indigo-500 hover:text-indigo-800 cursor-pointer pb-2">
            About Players Circle
          </p>
          <div class="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">
            <div class="w-full lg:w-6/12">
              <h2 class="w-full font-bold lg:text-4xl text-3xl lg:leading-10 dark:text-white leading-9">
                We are here to keep every player informed
              </h2>
              <p class="font-normal text-base leading-6 text-gray-600 dark:text-gray-200 mt-6">
                Players Circle is an exclusive membership for players who have
                privileges to our tools that will guide and collaborate with
                their wagering. At Players Circle the Bettor has all the
                necessary information right on their fingertips. From Hot off
                the Press, to What’s News which provide a plethora of content in
                Realtime; To assure you do not miss anything before you make
                that important decision on who to wager on.
              </p>
            </div>
            <div class="w-full lg:w-6/12">
              <img
                class="lg:block hidden w-full rounded"
                src={Cheering}
                // src="https://i.ibb.co/RjNH7QB/Rectangle-122-1.png"
                alt="Fans cheering"
              />
              <img
                class="lg:hidden sm:block hidden w-full rounded"
                src={Cheering}
                // src="https://i.ibb.co/16fPqrg/Rectangle-122-2.png"
                alt="Fans cheering"
              />
              <img
                class="sm:hidden block w-full rounded"
                // src="https://i.ibb.co/Jxhpxh6/Rectangle-122.png"
                src={Cheering}
                alt="Fans cheering"
              />
            </div>
          </div>

          <div class="relative mt-24">
            <div class="grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
              <div class="z-20 w-12 h-12 bg-gray-800 rounded-full flex justify-center items-center">
                <img
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg1.svg"
                  alt="flag"
                />
              </div>

              <img
                class="z-20"
                src="https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg2.svg"
                alt="note"
              />

              <img
                class="z-20 sm:block hidden"
                src="https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg3.svg"
                alt="users"
              />
            </div>
            <hr class="z-10 absolute top-2/4 w-full bg-gray-200" />
          </div>
          <div class="grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
            <div>
              <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 dark:text-white mt-6">
                Emblem
              </p>
              <p class="font-normal text-base leading-6 text-gray-600 dark:text-gray-200 mt-6">
                At Players Circle we tailor our services for you. We offer a
                variety of useful information to enhance your betting odds.
              </p>
            </div>
            <div>
              <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 dark:text-white mt-6">
                Community
              </p>
              <p class="font-normal text-base leading-6 text-gray-600 dark:text-gray-200 mt-6">
                We welcome all sports fans to view, retweet, or comment on all
                of our tweets.
              </p>
            </div>
            <div class="sm:block hidden">
              <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 dark:text-white mt-6">
                Trust
              </p>
              <p class="font-normal text-base leading-6 text-gray-600 dark:text-gray-200 mt-6">
                We provide the most up to date information and accurately
                portray what sport events are happening right now.
              </p>
            </div>
          </div>
          <div class="sm:hidden block relative mt-8">
            <div class="grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
              <img
                src="https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg3.svg"
                alt="user"
              />
            </div>
            <hr class="z-10 absolute top-2/4 w-full bg-gray-200" />
          </div>
          <div class="sm:hidden grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
            <div>
              <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800 dark:text-white mt-6">
                Trust
              </p>
              <p class="font-normal text-base leading-6 text-gray-600 dark:text-gray-200 mt-6">
                We provide the most up to date information and accurately
                portray what sport events are happening right now.
              </p>
            </div>
          </div>

          <div class="flex hidden lg:flex lg:flex-row flex-col md:gap-14 gap-16 justify-between lg:mt-20 mt-16">
            <img className="w-full rounded" src={Banner} alt="" />
          </div>

          <div class="flex lg:flex-row flex-col md:gap-14 gap-16 justify-between lg:mt-20 mt-16">
            <div class="w-full lg:w-6/12">
              <h2 class="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 dark:text-white">
                Our Mission
              </h2>
              <p class="font-normal text-base leading-6 text-gray-600 dark:text-gray-200 mt-6 w-full lg:w-10/12 xl:w-9/12">
                One of the foundational beliefs we thrive on is “The better a
                Player is equipped, The more educated decisions he will make.”
                This principal is the reason we provide the information
                necessary, for the Players to equip themselves prior to placing
                their wagers. We strongly recommend that you take a few minutes
                to go over the website and get acclimated with the different
                sections. This will surely pay dividends on the potential
                winnings you will enjoy.
              </p>
              <p class="font-normal text-base leading-6 text-gray-600 dark:text-gray-200 w-full lg:w-10/12 xl:w-9/12 mt-10">
                You will find tools such as Coaches Corner, which provide
                definitions and explanations of how and when to bet. A Bet
                Calculator to confirm that the wagers you make are paying the
                true amount of winnings. Under Tutorials you have an entire
                library of content which provides “how to” instructions.
                Finally, Streetlines is a Realtime line service that gives you
                all the latest odds, view line moves while basing decisions on
                who to wager on. We are confident these tools will assist you on
                winning your wagers. Now go ahead explore and educate yourself
                so you can truly be called A Player!
              </p>
            </div>
            <div class="w-full lg:w-6/12">
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 lg:gap-12 gap-10">
                {/* <!-- Board Card --> */}
                <div class="flex p-4 shadow-md bg-white rounded">
                  <div class="mr-6">
                    <FaChessKing size={30} color="#1e2937" />
                  </div>
                  <div class="">
                    <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800">
                      Exclusivity
                    </p>
                    <p class="mt-2 font-normal text-base leading-6 text-gray-600">
                      As mentioned, Players Circle is a member only site, so you
                      will have access to this privilege so long as you maintain
                      your account current.
                    </p>
                  </div>
                </div>
                <div class="flex p-4 shadow-md bg-white rounded">
                  <div class="mr-6">
                    <FaTools size={30} color="#1e2937" />
                  </div>
                  <div class="">
                    <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800">
                      Tools
                    </p>
                    <p class="mt-2 font-normal text-base leading-6 text-gray-600">
                      Coaches Corner, Bet Calculator & StreetLines are all tools
                      provided to you at hand to ensure you make the most
                      informed bets!
                    </p>
                  </div>
                </div>

                {/* <!-- Press Card --> */}
                <div class="flex p-4 shadow-md bg-white rounded">
                  <div class="mr-6">
                    <FaRegHandshake size={30} color="#1e2937" />
                  </div>
                  <div class="">
                    <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800">
                      Support
                    </p>
                    <p class="mt-2 font-normal text-base leading-6 text-gray-600">
                      We are here to enhance your experience as a player. We
                      provide 24/7 support & maintence to ensure you are
                      satisfied.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <StreetLines
          modal={modal}
          closeModal={closeModal}
          openModal={openModal}
        />
      </section>
    </>
  );
};

export default AboutScreen;
