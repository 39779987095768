import React, { useEffect, useState } from "react";
import UnAvailable from "../../Assets/images/unavailable.jpg";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const HotOffPressListItem = ({
  data,
  openStoryModal,
  setStoryText,
  setStoryHero,
}) => {
  const [scrollTop, setScrollTop] = useState();
  const [, setScrolling] = useState();
  const [fullStory, setFullStory] = useState();

  useEffect(() => {
    const fs = async () => {
      const split = data?.links.api.news.href.split("/");
      let res = await axios.get(`/api/news/${split[split.length - 1]}`);
      let json = await JSON.parse(res.data);
      setFullStory(json);
      if (json.code === 403) return null;
    };
    fs();
  }, []);

  useEffect(() => {
    function isVisible(ele) {
      const { top, bottom } = ele.getBoundingClientRect();
      const vHeight =
        window.innerHeight || document.documentElement.clientHeight;

      return (top > 0 || bottom > 0) && top < vHeight;
    }

    document?.querySelectorAll(".hofLi").forEach((item, index) => {
      if (isVisible(item)) {
        if (index % 2 === 0) {
          item.classList.add("animate__backInRight");
        } else {
          item.classList.add("animate__backInLeft");
        }
      } else {
        item.classList.remove("animate__backInRight");
        item.classList.remove("animate__backInLeft");
      }
    });

    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <>
      <section
        className={`container mx-auto w-96 md:w-full flex flex-col md:flex-row md:items-center shadow-lg p-5 hofLi animate__animated bg-white my-10 md:p-0`}
      >
        <img
          className="w-full object-cover md:w-1/2"
          src={
            data?.images[0]?.url.length !== undefined
              ? data?.images[0]?.url
              : UnAvailable
          }
          alt={data?.images[0]?.credit}
        />
        <div className="md:w-1/2 md:p-5">
          <h3 className="text-xl md:text-[16px] md:leading-[18px] mt-3 md:mt-0">
            {data?.description}
          </h3>
          {fullStory?.code ? null : (
            <button
              className="rounded border-none bg-[#091368] text-white mt-3 p-2 text-lg sm:text-2xl sm:p-3 md:text-xl w-full flex flex-row items-center justify-between hover:bg-blue-700 cursor-pointer"
              onClick={() => {
                openStoryModal();
                setStoryText(fullStory?.headlines[0].story);
                setStoryHero(
                  data?.images[0]?.url.length !== undefined
                    ? data?.images[0]?.url
                    : UnAvailable
                );
              }}
            >
              View Story <FontAwesomeIcon icon={faArrowRight} />
            </button>
          )}
        </div>
      </section>
    </>
  );
};

export default HotOffPressListItem;
