import React, {useEffect, useState} from "react";
import styles from "./Header.module.scss";
import {NavLink} from "react-router-dom";

const Header = ({hotOffThePressRef, setBetCalculatorModal}) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const header = document.querySelector("header");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <header className={`${styles.container} ${scroll > headerTop ? styles.stick : "header-top"}`}>
      <ul className={styles.ul}>
        <li className={`${styles.nav__item} ${styles.parallelogram} hover:bg-blue-700`} onClick={() => setBetCalculatorModal(true)} style={{cursor: "pointer"}}><span>ACCESS BET CALCULATOR!</span></li>
        <li className={styles.nav__item}>
          <NavLink to={process.env.PUBLIC_URL + "/"} className={styles.remove__underline}
                   onClick={() => hotOffThePressRef?.current?.scrollIntoView({behavior: "smooth"})}>
            HOME
          </NavLink>
        </li>
        <li className={styles.nav__item}>
          <NavLink to={process.env.PUBLIC_URL + "/baseball-chew"}
                   className={styles.remove__underline}
                   onClick={() => hotOffThePressRef?.current?.scrollIntoView({behavior: "smooth"})}>
            BASEBALL
          </NavLink>
        </li>
        <li className={styles.nav__item}>
          <NavLink to={process.env.PUBLIC_URL + "/whats-up-hoops"}
                   className={styles.remove__underline}
                   onClick={() => hotOffThePressRef?.current?.scrollIntoView({behavior: "smooth"})}>
            BASKETBALL
          </NavLink>
        </li>
        <li className={styles.nav__item}>
          <NavLink to={process.env.PUBLIC_URL + "/tough-turf"} className={styles.remove__underline}
                   onClick={() => hotOffThePressRef?.current?.scrollIntoView({behavior: "smooth"})}>
            FOOTBALL
          </NavLink>
        </li>
        <li className={styles.nav__item}>
          <NavLink to={process.env.PUBLIC_URL + "/survival-of-the-fittest"} className={styles.remove__underline}
                   onClick={() => hotOffThePressRef?.current?.scrollIntoView({behavior: "smooth"})}>
            UFC
          </NavLink>
        </li>
        <li className={styles.nav__item}>
          <NavLink to={process.env.PUBLIC_URL + "/ice-cold-facts"} className={styles.remove__underline}
                   onClick={() => hotOffThePressRef?.current?.scrollIntoView({behavior: "smooth"})}>
            HOCKEY
          </NavLink>
        </li>
        <li className={styles.nav__item}>
          <NavLink to={process.env.PUBLIC_URL + "/goal-time"} className={styles.remove__underline} onClick={() => hotOffThePressRef?.current?.scrollIntoView({behavior: "smooth"})}>
            SOCCER
          </NavLink>
        </li>
      </ul>
    </header>
  );
};

export default Header;