import React, { useState, useEffect } from "react";
import { Link,Outlet,
  useMatch,
  useResolvedPath, } from "react-router-dom";
// import {Carat} from "react-icons/all";
import {
  FaCalculator,
  FaChartLine,
  FaGripLinesVertical,
  FaHeadset,
  FaHome,
  FaInfo,
  FaMailBulk,
  FaVideo,
} from "react-icons/fa";

const MobileMenu = ({
  openSportsLines,
  hotOffThePressRef,
  setBetCalculatorModal,
}) => {
  const [toggle, setToggle] = useState(false);

  const handleStreetLines = () => {
    setToggle(false);
    openSportsLines();
  };

  useEffect(() => {
    let specifiedElement = document.getElementById("menuToggle");

    document.addEventListener("click", function (event) {
      let isClickInside = specifiedElement.contains(event.target);

      if (!isClickInside) {
        //the click was outside the specifiedElement
        setToggle(false);
      }
    });
  }, []);

  return (
    <nav role="navigation">
      <div id="menuToggle">
        <input
          className="hamburger"
          onClick={() => setToggle(!toggle)}
          type="checkbox"
          checked={toggle}
          readOnly
        />
        <span />
        <span />
        <span />
        <ul
          id="menu"
          className="overflow-y-auto py-4 px-3 bg-gray-50 rounded dark:bg-gray-800"
        >
          <li onClick={() => setToggle(false)} className="flex justify-center border-b border-gray-600 border-solid mb-5">
            <Link to="/" className="flex items-center pl-2.5 mb-5">
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                className="h-20 mr-3 sm:h-28"
                alt="Flowbite Logo"
              />
              {/* <p className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Players Circle</p> */}
            </Link>
          </li>
          <li onClick={() => setToggle(false)}>
            <CustomLink
              to="/"
              className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <FaHome className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <p className="ml-3">Home</p>
            </CustomLink>
          </li>
          <li onClick={() => setToggle(false)}>
            <CustomLink
              to="/about"
              className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <FaInfo className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <p className="flex-1 ml-3 whitespace-nowrap">About</p>
              {/* <p className="inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">UTILITY</p> */}
            </CustomLink>
          </li>
          <li onClick={() => setToggle(false)}>
            <CustomLink
              to="/live-scores"
              className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <FaChartLine className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <p className="flex-1 ml-3 whitespace-nowrap">Live Scores</p>
              <p className="inline-flex justify-center items-center p-3 ml-3 h-3 text-sm font-medium text-green-600 bg-blue-200 rounded-full dark:bg-green-900 dark:text-green-200">
                24/7
              </p>
            </CustomLink>
          </li>
          <li onClick={() => setToggle(false)}>
            <CustomLink
              to="/coaches-corner"
              className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <FaHeadset className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <p className="flex-1 ml-3 whitespace-nowrap">Coaches Corner</p>
            </CustomLink>
          </li>
          <li onClick={() => setToggle(false)}>
            <CustomLink
              to="/tutorials"
              className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <FaVideo className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <p className="flex-1 ml-3 whitespace-nowrap">Tutorials</p>
            </CustomLink>
          </li>
          <li onClick={() => setToggle(false)}>
            <CustomLink
              to="/contact-us"
              className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <FaMailBulk className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <p className="flex-1 ml-3 whitespace-nowrap">Contact Us</p>
            </CustomLink>
          </li>
          <li onClick={() => [setToggle(false), setBetCalculatorModal(true)]}>
            <div className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
              <FaCalculator className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <p className="flex-1 ml-3 whitespace-nowrap">Bet Calculator</p>
              <p className="inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">UTILITY</p>

            </div>
          </li>
          <li onClick={() => handleStreetLines()}>
            <div className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
              <FaGripLinesVertical className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
              <p className="flex-1 ml-3 whitespace-nowrap">Street Lines</p>
              <p className="inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">UTILITY</p>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    // <Sidebar />
  );
};

export default MobileMenu;



function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div>
      <Link
        style={{ background: match && "rgb(55 65 81)"}}
        to={to}
        {...props}
      >
        {children}
      </Link>
      {/* {match && " (active)"} */}
    </div>
  );
}