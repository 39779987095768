import React from "react";
import MetaTags from "react-meta-tags";
import styles from "./NotFound.module.scss";
import {useLocation} from "react-router-dom";
import StreetLines from "../../Components/StreetLines";

const NotFound = ({modal, openModal, closeModal}) => {
  const location = useLocation();
  const {pathname} = location;

  return (
    <>
      <MetaTags>
        <title>Players Circle - Page Not Found</title>
        <meta
          name="description"
          content="404 Error"
        />
      </MetaTags>
      <section className={styles.errorPageContainer}>
        <h2>404 Error: Iloveto.bet{pathname} Does Not Exist</h2>
      </section>
      <StreetLines modal={modal} closeModal={closeModal} openModal={openModal}/>

    </>
  )
}

export default NotFound;