import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Twitter from "../../Assets/images/twitter.png";
import CheckIcon from "../../Assets/images/check.svg";
import ErrorIcon from "../../Assets/images/error.svg";
import PromotionalBanner from "../../Assets/images/promotional-bannerx.png";
import styles from "./HomeScreen.module.scss";
import HotOffPressList from "../../Components/HotOffPress/HotOffPressList";
import { useLocation } from "react-router-dom";
import StreetLines from "../../Components/StreetLines";
import Tooltip from "./ToolTip";
import Toast from "../../Components/Toast/Toast";
import moment from "moment";
import DummyData from "./DummyData.json";
import { firestore } from "../../firebase";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";

const HomeScreen = ({
  hotOffThePressRef,
  openModal,
  closeModal,
  modal,
  openStoryModal,
  closeStoryModal,
  storyModal,
  storyText,
  setStoryText,
  setStoryHero,
  storyHero,
}) => {
  const location = useLocation();
  const [fetchedNews, setFetchedNews] = useState(false);
  const [checkValue] = useState(true);
  const [autoDeleteTime] = useState(1000);
  const [loading, setLoading] = useState(true);
  const [tweets, setTweets] = useState([]);

  useEffect(() => {
    // Function to fetch tweets from Firestore collection
    const fetchTweets = async () => {
      try {
        const tweetsRef = collection(firestore, "tweets"); // Reference to the 'tweets' collection
        const q = query(tweetsRef, orderBy("created_at", "desc"), limit(10)); // Order by 'created_at' in descending order and limit to 10 documents
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const tweetData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTweets(tweetData);
          setLoading(false);
        });

        return () => unsubscribe(); // Unsubscribe from the snapshot listener when the component unmounts
      } catch (error) {
        console.error("Error fetching tweets:", error);
        setLoading(false);
      }
    };

    fetchTweets();
  }, []);

  const renderSkeleton = () => {
    return [...Array(5)].map((e, i) => (
      <div
        role="status"
        className="bg-white p-5 my-5 space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center flex-col"
      >
        <div className="flex justify-center items-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
          <svg
            className="w-12 h-12 text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 640 512"
          >
            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"></path>
          </svg>
        </div>
        <div className="w-full">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mt-4"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mt-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mt-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mt-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mt-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mt-2.5"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    ));
  };
  const twitterContent = () => (
    <div className="h-[650px] lg:h-[1740px] overflow-auto">
      {tweets.map((data) => (
        <a href={data.statusUrl} rel="noopener noreferrer" target="_blank">
          <div className="flex items-center justify-center">
            <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 border lg:max-w-xl">
              <div className="flex justify-between">
                <div className="flex items-center">
                  <img
                    className="h-11 w-11 rounded-full"
                    src="https://pbs.twimg.com/profile_images/1356027761811451906/eoCGqeKZ_400x400.jpg"
                  />
                  <div className="ml-1.5 text-sm leading-tight">
                    <span className="text-black dark:text-white font-bold block ">
                      plnetworksllc
                    </span>
                    <span className="text-gray-500 dark:text-gray-400 font-normal block">
                      @plnetworksllc
                    </span>
                  </div>
                </div>
                <svg
                  className="text-blue-400 dark:text-white h-6 w-auto inline-block fill-current"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path>
                  </g>
                </svg>
              </div>
              <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                {data.text}
              </p>
              <img
                className="mt-2 rounded-2xl border border-gray-100 dark:border-gray-700 w-full"
                src={data.preview_image_url}
              />
              <p className="text-gray-500 dark:text-gray-400 text-base py-1 my-0.5">
                {moment(data.created_at).fromNow()} ·{" "}
                {moment(data.created_at).format("MMM DD, YYYY")} ·{" "}
                {moment(data.created_at).format("h:mm A")}
              </p>
              <div className="border-gray-200 dark:border-gray-600 border border-b-0 my-1"></div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );

  useEffect(() => {
    function onKeyup(e) {
      if (e.keyCode === 27) {
        closeStoryModal();
      }
    }

    window.addEventListener("keydown", onKeyup);
  }, []);

  const { pathname } = location;

  const successList = [
    {
      id: 1,
      title: "You're up to date!",
      description: `We have updated you with the latest sports news articles as of ${moment().format(
        "hh:mm:ss a"
      )}. Thank you for viewing this content!`,
      backgroundColor: "#5cb85c",
      icon: CheckIcon,
    },
  ];

  const errorList = [
    {
      id: 2,
      title: "Danger",
      description:
        "Uh oh. Something went wrong. Please try again or submit the form later.",
      backgroundColor: "#d9534f",
      icon: ErrorIcon,
    },
  ];

  // if (loading) return <Spinner />;

  return (
    <>
      {fetchedNews && (
        <Toast
          toastList={successList}
          position="top-right"
          autoDelete={checkValue}
          autoDeleteTime={autoDeleteTime}
          dismissTime={5000}
        />
      )}

      <MetaTags>
        {pathname === "/" && <title>Welcome to Players Circle</title>}
        {pathname === "/baseball-chew" && <title>Baseball Chew</title>}
        {pathname === "/whats-up-hoops" && (
          <title>Basketball - What's Up Hoops!</title>
        )}
        {pathname === "/tough-turf" && <title>Football - Tough Turf</title>}
        {pathname === "/survival-of-the-fittest" && (
          <title>UFC - Survival Of The Fittest</title>
        )}
        {pathname === "/ice-cold-facts" && (
          <title>Hockey - Ice Cold Facts</title>
        )}
        {pathname === "/goal-time" && <title>Soccer - Goal Time</title>}

        <meta name="description" content="Homepage" />
      </MetaTags>

      <section className={styles.container} style={{ overflowX: "hidden" }}>
        <section
          className={`${styles.hotOffPressContainer} lg:p-[20px]`}
          style={{ overflow: "hidden" }}
        >
          <img
            className={styles.circles}
            src="https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/circles.png"
            alt=""
          />
          <img className={styles.bannerAd} src={PromotionalBanner} alt="" />
          <div className="flex items-center justify-center lg:justify-between ">
            <h2
              id="hotOffThePress"
              className={styles.hotOffThePress}
              ref={hotOffThePressRef}
            >
              HOT OFF THE PRESS
            </h2>
            {!fetchedNews && (
              <Tooltip
                fetchedNews={fetchedNews}
                setFetchedNews={setFetchedNews}
              />
            )}
          </div>
          <HotOffPressList
            setStoryText={setStoryText}
            storyModal={storyModal}
            openStoryModal={openStoryModal}
            closeStoryModal={closeStoryModal}
            fetchedNews={fetchedNews}
            setFetchedNews={setFetchedNews}
            setStoryHero={setStoryHero}
          />
        </section>
        <section className={styles.twitterFeed}>
          <div className={styles.twitterFeedHeading}>
            <img src={Twitter} className={styles.twitterLogo} alt="" />
            <h2 className={styles.whatsNews}>What's News</h2>
          </div>
          <div className="w-full desktop:w-96 p-5 lg:p-[20px]">
            {!loading ? (
              tweets.length === 0 ? (
                <div className="h-96 overflow-auto">
                  <a
                    // href={data.statusUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="flex items-center justify-center">
                      <div className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 border lg:max-w-xl">
                        <div className="flex justify-between">
                          <div className="flex items-center">
                            <img
                              className="h-11 w-11 rounded-full"
                              src="https://pbs.twimg.com/profile_images/1356027761811451906/eoCGqeKZ_400x400.jpg"
                            />
                            <div className="ml-1.5 text-sm leading-tight">
                              <span className="text-black dark:text-white font-bold block ">
                                plnetworksllc
                              </span>
                              <span className="text-gray-500 dark:text-gray-400 font-normal block">
                                @plnetworksllc
                              </span>
                            </div>
                          </div>
                          <svg
                            className="text-blue-400 dark:text-white h-6 w-auto inline-block fill-current"
                            viewBox="0 0 24 24"
                          >
                            <g>
                              <path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path>
                            </g>
                          </svg>
                        </div>
                        <p className="text-black dark:text-white block text-xl leading-snug mt-3">
                          There is no timeline at the moment. Please check back
                          later.
                        </p>
                        <p className="text-gray-500 dark:text-gray-400 text-base py-1 my-0.5">
                          Follow us{" "}
                          <a
                            href="https://twitter.com/plnetworksllc"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="underline text-gray-500 dark:text-gray-400"
                          >
                            here
                          </a>
                        </p>
                        {/* <img
                className="mt-2 rounded-2xl border border-gray-100 dark:border-gray-700 w-full"
                src={data.preview_image_url}
              />
              <p className="text-gray-500 dark:text-gray-400 text-base py-1 my-0.5">
                {moment(data.created_at).fromNow()} ·{" "}
                {moment(data.created_at).format("MMM DD, YYYY")} ·{" "}
                {moment(data.created_at).format("h:mm A")}
              </p> */}
                        <div className="border-gray-200 dark:border-gray-600 border border-b-0 my-1"></div>
                      </div>
                    </div>
                  </a>
                </div>
              ) : (
                twitterContent()
              )
            ) : null}
            {loading ? (
              <div className="timeline-skeleton">
                <div className="timeline-header">
                  Tweets from @plnetworksllc
                </div>
                {renderSkeleton()}
              </div>
            ) : null}
            <img
              className={styles.circlesRight}
              src="https://sportslogos.nyc3.cdn.digitaloceanspaces.com/playerscircle/circles.png"
              alt=""
            />
          </div>
        </section>
      </section>
      <section className={styles.container}>
        <StreetLines
          autoScroll
          modal={modal}
          openModal={openModal}
          closeModal={closeModal}
        />
      </section>
      {storyModal && (
        <div
          id="extralarge-modal"
          tabIndex="-1"
          className="z-50 fixed inset-0 bg-gray-500 bg-opacity-50 h-full w-full"
        >
          <div className="relative p-4 w-full h-full inset-0 md:top-20 mx-auto p-5 border lg:w-2/3 md:h-5/6 rounded-md">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col h-full">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center p-5 rounded-t border-b dark:border-solid dark:border-gray-600 flex-1">
                <h3 className="text-xl font-medium text-gray-900 dark:text-white uppercase">
                  Hot Off The Press: Full Story
                </h3>
                <button
                  type="button"
                  onClick={() => closeStoryModal()}
                  className="cursor-pointer text-gray-400 bg-transparent border-none hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="extralarge-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6 overflow-y-auto flex-[10] md:h-[35rem]">
                {/* // img here */}

                <div className="w-full h-auto">
                  <img
                    className="w-full"
                    src={storyHero}
                    alt="Hero story image"
                  />
                </div>
                <div
                  id={styles.full_Story_modal_body}
                  className="text-base leading-relaxed text-gray-200 dark:text-gray-200"
                  dangerouslySetInnerHTML={{ __html: storyText }}
                />
              </div>
              {/* <!-- Modal footer --> */}
              <div className="flex items-center p-6 space-x-2 rounded-b border-t border-solid border-gray-200 dark:border-gray-600 flex-1">
                <button
                  data-modal-toggle="extralarge-modal"
                  type="button"
                  className="cursor-pointer text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => closeStoryModal()}
                >
                  Close Story
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeScreen;
