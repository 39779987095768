import React from "react";
import styles from "./SelectLeague.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const SelectLeague = ({currentSport, handleChange, selectOptions, handleSearch, searchQuery, setSearchQuery}) => {
  return (
    <section id={styles.select__league} className={styles.container}>
      <label htmlFor="search"/>
      <section className={styles.select__league__container}>
        <input className={styles.searchInput} type="text" id="search" value={searchQuery}
               onChange={handleSearch}
               placeholder="ex. Brooklyn Nets"/>
        {searchQuery.length > 0 && <span className={styles.select__league__clear__button}
                                         onClick={() => setSearchQuery("")}>
          <FontAwesomeIcon
            icon={faTimesCircle}/>
                </span>}
      </section>
      <div className={styles.selectContainer}>
        <label htmlFor="sports"/>
        <select id="sports" className={styles.dropdown} name="sports" value={currentSport}
                onChange={handleChange}>
          {selectOptions.map((option) => (
            <option value={option} key={option}>{option}</option>
          ))}
        </select>
      </div>
    </section>
  )
}

export default SelectLeague;
