import React from "react";
import SelectLeague from "../../Components/SelectLeague/SelectLeague";
import CurrentSportsLines from "../../Components/CurrentSportsLines/CurrentSportsLines";
import NoData from "../../Assets/images/unavailable.jpg";
// import ReactLoading from "react-loading";
import "./StreetLines.scss";
import Spinner from "../Spinner/Spinner";

const StreetLinesComp = ({
  options,
  currentSport = options[0],
  loading,
  lines,
  selectOptions,
  searchQuery,
  half,
  setHalf,
  setSearchQuery,
  setCurrentSport,
  backgroundColor,
  color,
  setLines,
  closeModal,
}) => {
  const handleSearch = ({ target: { value } }) => setSearchQuery(value);

  const getCurrentSportLines = (currentSport) => {
    let results = lines.filter((line) => line.League === currentSport);
    let counter = 0;
    if (results.length > 0) {
      return results;
    } else {
      if (lines.length > 0) {
        setCurrentSport(options[counter++]);
        return results;
      } else {
        setLines([]);
      }
    }
  };

  const handleSportChange = ({ target: { value } }) => {
    setCurrentSport(value);
    setSearchQuery("");
  };

  return (
    <section id="home__page">
      <SelectLeague
        currentSport={currentSport}
        handleChange={handleSportChange}
        selectOptions={selectOptions}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <section className="home__page__container">
        {!loading ? (
          lines.length > 0 ? (
            <CurrentSportsLines
              currentSport={currentSport}
              currentLines={getCurrentSportLines(currentSport)}
              searchQuery={searchQuery}
              half={half}
              setHalf={setHalf}
              backgroundColor={backgroundColor}
              color={color}
              closeModal={closeModal}
            />
          ) : (
            <section className="home__page__no__data__container">
              <img className="noData" src={NoData} alt="" />
            </section>
          )
        ) : (
          <section className="home__page__loading__container">
            <Spinner />
          </section>
        )}
      </section>
    </section>
  );
};

export default StreetLinesComp;
