import React, { useState } from "react";
import styles from "./ContactScreen.module.scss";
import axios from "axios";
import Toast from "../../Components/Toast/Toast";
import CheckIcon from "../../Assets/images/check.svg";
import ErrorIcon from "../../Assets/images/error.svg";
import Circles from "../../Assets/images/sound-pulse.svg";
import MetaTags from "react-meta-tags";
import StreetLines from "../../Components/StreetLines";

const Contact = ({ modal, closeModal, openModal }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [categories] = useState([
    "Other",
    "Tech Support",
    "Account Issues",
    "Questions or Inquiry",
  ]);
  const [category, setCategory] = useState("OTHER");
  const [message, setMessage] = useState("");
  const [currentlyUsedChars, setCurrentlyUsedChars] = useState(0);
  const [checkValue] = useState(true);
  const [autoDeleteTime] = useState(1000);

  const [success, setSuccess] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    if (firstName && lastName && email && message) {
      const data = {
        Field1: firstName,
        Field2: lastName,
        Field3: email,
        Field9: category,
        Field5: message,
      };

      const res = await axios.post("/api/wufoo", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (JSON.parse(res.data).Success === 1) {
        setFormSubmitted(true);
        setSuccess(true);
        setTimeout(() => setFormSubmitted(false), 3000);
        setTimeout(() => setSuccess(false), 3000);
      } else {
        setFormSubmitted(true);
        setSuccess(false);
        setTimeout(() => setFormSubmitted(false), 3000);
      }
      setCurrentlyUsedChars(0);
      setFirstName("");
      setLastName("");
      setEmail("");
      setCategory("Other");
      setMessage("");
    }
  };

  const handleChange = ({ target: { value } }) => {
    setCurrentlyUsedChars(value.length);
    setMessage(value);
  };

  const successList = [
    {
      id: 1,
      title: "Success",
      description:
        "We have successfully received your response. We will get back to you shortly. Thank you!",
      backgroundColor: "#5cb85c",
      icon: CheckIcon,
    },
  ];

  const errorList = [
    {
      id: 2,
      title: "Danger",
      description:
        "Uh oh. Something went wrong. Please try again or submit the form later.",
      backgroundColor: "#d9534f",
      icon: ErrorIcon,
    },
  ];

  return (
    <>
      {success && formSubmitted && (
        <Toast
          toastList={successList}
          position="top-right"
          autoDelete={checkValue}
          autoDeleteTime={autoDeleteTime}
          dismissTime={3000}
        />
      )}

      {!success && formSubmitted && (
        <Toast
          toastList={errorList}
          position="top-right"
          autoDelete={checkValue}
          autoDeleteTime={autoDeleteTime}
          dismissTime={3000}
        />
      )}
      <MetaTags>
        <title>Players Circle - Contact Us</title>
        <meta name="description" content="Contact us" />
      </MetaTags>
      <section className="bg-[#162349] text-gray-100 px-8 py-12">
        <div className="max-w-screen-xl my-5 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg">
          <div className="flex flex-col justify-between">
            <div>
              <h2 className="text-4xl lg:text-5xl font-bold leading-tight">
                Reach out to us!
              </h2>
              <div className="text-gray-700 mt-8">
                Hate forms? Send us an <span className="underline">
                  <a href="mailto:info@plnetworkllc.com" target="_blank" rel="noopener noreferrer">email</a>
                </span> instead.
              </div>
            </div>
            <div className="mt-8 text-center max-w-7xl">
              <img
                src={process.env.PUBLIC_URL + "/contact.svg"}
                className="w-full"
              />
            </div>
          </div>
          <form onSubmit={submitForm}>
            <div>
              <span className="uppercase text-sm text-gray-600 font-bold">
                Select Category
              </span>

              <select
                name="category"
                id="category"
                onChange={({ target: { value } }) => setCategory(value)}
                value={category}
                className="bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 mt-2"
              >
                {categories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-8">
              <span className="uppercase text-sm text-gray-600 font-bold">
                <span className={styles.required}>*</span> First Name
              </span>
              <input
                className="w-full bg-gray-300 border-none text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="John"
                required
                onChange={({ target: { value } }) => setFirstName(value)}
                value={firstName}
                name="firstName"
              />
            </div>
            <div className="mt-8">
              <span className="uppercase text-sm text-gray-600 font-bold">
                <span className={styles.required}>*</span> Last Name
              </span>
              <input
                className="w-full bg-gray-300 border-none text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                required
                onChange={({ target: { value } }) => setLastName(value)}
                value={lastName}
                placeholder="Doe"
                name="lastName"
              />
            </div>
            <div className="mt-8">
              <span className="uppercase text-sm text-gray-600 font-bold">
                <span className={styles.required}>*</span> Email
              </span>
              <input
                className="w-full bg-gray-300 border-none text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="email"
                onChange={({ target: { value } }) => setEmail(value)}
                required
                placeholder="johndoe@gmail.com"
                value={email}
                name="email"
              />
            </div>
            <div className="mt-8">
              <span className="uppercase text-sm text-gray-600 font-bold">
                <span className={styles.required}>*</span> Message (
                {1000 - currentlyUsedChars} characters left)
              </span>
              <textarea
                className="w-full h-32 bg-gray-300 border-none text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline resize-none"
                required
                name="message"
                placeholder="Enter a message"
                maxLength={1000}
                value={message}
                onChange={handleChange}
              />
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className="uppercase cursor-pointer hover:bg-indigo-700 text-sm font-bold border-none tracking-wide bg-indigo-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </section>
      <StreetLines
        modal={modal}
        closeModal={closeModal}
        openModal={openModal}
      />
    </>
  );
};

export default Contact;
