import React from "react";
import MetaTags from "react-meta-tags";
import "./Privacy.scss";
import StreetLines from "../../Components/StreetLines";
import { Link } from "react-router-dom";
const Privacy = ({ modal, closeModal, openModal }) => {
  return (
    <>
      <MetaTags>
        <title>Players Circle - Privacy Policy</title>
        <meta name="description" content="Privacy Policy" />
      </MetaTags>

      <section className="border-b border-solid border-gray-300 grow">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pb-12 md:pb-20">
            <div className="max-w-3xl mx-auto lg:max-w-none">
              <article>
                {/* Article header */}
                {/* <header className="max-w-3xl mx-auto mb-20"> */}
                {/* Title */}
                <div className="pt-32 max-w-3xl pb-12 md:pb-20 text-center md:text-left">
                  <h1 className="h1">Privacy Policy</h1>
                </div>
                {/* </header> */}

                {/* Article content */}
                <div
                  className="lg:flex lg:justify-between"
                  data-sticky-container
                >
                  {/* Sidebar */}
                  <aside className="relative hidden lg:block w-64 mr-20 shrink-0">
                    <div
                      data-sticky
                      data-margin-top="100"
                      data-sticky-for="768"
                      data-sticky-wrap
                    >
                      <h4 className="text-lg font-bold leading-snug tracking-tight mb-4">
                        Table of contents
                      </h4>
                      <ul className="font-medium -my-1">
                        <li className="py-2">
                          <a
                            className="flex items-center hover:underline"
                            href="#data-usage"
                          >
                            <svg
                              className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Data Usage</span>
                          </a>
                        </li>
                        <li className="py-2">
                          <a
                            className="flex items-center hover:underline"
                            href="#identity"
                          >
                            <svg
                              className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>User Identity</span>
                          </a>
                        </li>
                        <li className="py-2">
                          <a
                            className="flex items-center hover:underline"
                            href="#additional-information"
                          >
                            <svg
                              className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Additional Information</span>
                          </a>
                        </li>
                        <li className="py-2">
                          <a
                            className="flex items-center hover:underline"
                            href="#consent"
                          >
                            <svg
                              className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Consent</span>
                          </a>
                        </li>
                        <li className="py-2">
                          <a
                            className="flex items-center hover:underline"
                            href="#cookies"
                          >
                            <svg
                              className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Cookies</span>
                          </a>
                        </li>
                        <li className="py-2">
                          <a
                            className="flex items-center hover:underline"
                            href="#opt-out"
                          >
                            <svg
                              className="w-4 h-4 fill-current text-gray-400 mr-3 shrink-0"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Opting-Out</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </aside>

                  {/* Main content */}
                  <div>
                    {/* Article meta */}
                    <div className="flex items-center mb-6">
                      <div className="flex shrink-0 mr-3">
                        <a className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={
                              "https://pbs.twimg.com/profile_images/1356027761811451906/eoCGqeKZ_400x400.jpg"
                            }
                            width="32"
                            height="32"
                            alt="Author 04"
                          />
                        </a>
                      </div>
                      <div>
                        <span className="text-gray-600">Property of </span>
                        <a
                          href="https://twitter.com/plnetworksllc"
                          target="_blank"
                          rel="noreferrer"
                          className="font-medium hover:underline"
                        >
                          PLNETWORKS SRL
                        </a>
                        <span className="text-gray-600">
                          {" "}
                          · Last Updated July 17, 2020
                        </span>
                      </div>
                    </div>
                    <hr className="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                    {/* Article body */}
                    <div className="text-lg text-gray-600">
                      <p
                        id="data-usage"
                        className="mb-8 text-md md:text-2xl"
                        style={{ scrollMarginTop: "15px" }}
                      >
                        Data Usage
                      </p>
                      <p className="mb-8">
                        This describes the way which we handle your data and
                        information regarding your privacy. We reserve the right
                        to maintain all data in our internal servers and/or on
                        our backup servers which are encrypted and protected
                        using the utmost security in our possession. All
                        information provided to us by you will be managed by
                        only authorized personnel and only you and our employees
                        will be privy to the information on a need to know
                        basis.
                      </p>

                      <ul className="list-disc list-decimal mx-4 mb-8">
                        <li className="my-1">
                          A unique account name and password
                        </li>
                        <li className="my-1">Your full legal name</li>
                        <li className="my-1">
                          Your social security number or legal identification
                          number (based on your country residency)
                        </li>
                        <li className="my-1">Your physical address</li>
                        <li className="my-1">A valid email address</li>
                        <li className="my-1">Birth Date</li>
                        <li className="my-1">Telephone Number</li>
                      </ul>

                      <p
                        id="identity"
                        className="mb-8 text-md md:text-2xl"
                        style={{ scrollMarginTop: "15px" }}
                      >
                        User Identity
                      </p>

                      <p className="mb-8">
                        If your identity cannot be validated, you may be
                        required to submit additional information or
                        documentation. You will also be required to answer one
                        or more security questions, after which you will be sent
                        a confirmation via email.
                      </p>

                      <p
                        id="additional-information"
                        className="mb-8 text-md md:text-2xl"
                        style={{ scrollMarginTop: "15px" }}
                      >
                        Additional User Information
                      </p>

                      <p className="mb-8">
                        You may also be required to update the information or
                        data or to provide additional items as part of ongoing
                        efforts to prevent illegal and fraudulent activities.
                      </p>

                      <p
                        id="consent"
                        className="mb-8 text-md md:text-2xl"
                        style={{ scrollMarginTop: "15px" }}
                      >
                        User Consent
                      </p>

                      <p className="mb-8">
                        The use of our services consents you agree with our
                        handling of your information and data. In-addition you
                        consent we may use your data on third party websites for
                        confirmation and verity of such information provided.{" "}
                        <strong className="font-bold">
                          We warrant that any and all information provided will
                          not be used or sold to any third-party marketers or
                          list for profit.
                        </strong>{" "}
                        You consent we can and will make direct contact with you
                        via SMS, EMAIL, CHAT or any other form of communication
                        regarding our services and promotions for marketing
                        purposes.
                      </p>

                      <p
                        id="cookies"
                        className="mb-8 text-md md:text-2xl"
                        style={{ scrollMarginTop: "15px" }}
                      >
                        Cookies
                      </p>

                      <p className="mb-8">
                        We may also use Cookies to enhance your experience
                        however all information gathered and stored in any
                        Cookies will be encrypted using our SSL Certificates,
                        However we do not make or express guarantee from any of
                        the information leaking or being revealed via hacks or
                        security breaches. We will do our best to inform all
                        customers if such events happen via a communication
                        directly to you with steps on how to prevent further
                        loss of identity. If there are any links to third party
                        websites, we do not take any responsibility for the use
                        of those websites nor any information submitted by you
                        to them. We advise never to share your credentials with
                        anyone including our employees while seeking support.
                      </p>

                      <p
                        id="opt-out"
                        className="mb-8 text-md md:text-2xl"
                        style={{ scrollMarginTop: "15px" }}
                      >
                        Opt-out of Privacy Policy
                      </p>

                      <p className="mb-8">
                        You may always choose to opt-out of our privacy policy
                        by closing your account as noted in our terms of
                        service.
                      </p>

                      {/* end of content */}
                      <div>
                        <hr className="w-full h-px pt-px mt-8 bg-gray-200 border-0" />
                        <div className="mt-8">
                          Have any comments, questions or concerns?{" "}
                          <Link
                            to="/contact-us"
                            className="text-gray-900 underline"
                          >
                            Reach out
                          </Link>{" "}
                          to let us know.
                        </div>
                        <div className="mt-6">
                          <Link
                            to="/"
                            className="inline-flex items-center text-base text-blue-600 font-medium hover:underline"
                          >
                            <svg
                              className="w-3 h-3 fill-current text-blue-400 shrink-0 mr-2"
                              viewBox="0 0 12 12"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M.293 5.282L5 .5l1.414 1.436-3 3.048H12v2.032H3.414l3 3.048L5 11.5.293 6.718a1.027 1.027 0 010-1.436z" />
                            </svg>
                            <span>Back to the Home</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Article footer */}
              </article>
            </div>
          </div>
        </div>
        <StreetLines
          modal={modal}
          closeModal={closeModal}
          openModal={openModal}
        />
      </section>
      {/* <StreetLines modal={modal} closeModal={closeModal} openModal={openModal} /> */}
    </>
  );
};

export default Privacy;
